import {Autocomplete} from '@material-ui/lab';
import {Box, TextField} from '@material-ui/core';
import React from "react";
import {Role} from "../model/Role";

interface Props {
    onChange: (selected: string[]) => void;
    userRoles: string[];
    allRoles: Role[];
    disabled?: boolean;
}

const Admin2GetherRolesMultiSelector: React.FC<Props> = ({onChange, userRoles, allRoles, disabled}) => {
    let options: string[] = allRoles.map(r => r.roleName);

    const getRoleNamesByRoleIds = () => {
        return allRoles.filter(role => userRoles.includes(role.roleId)).map(role => role.roleName)
    }

    const getRoleIdsByRoleNames = (roleNames: string[]) => {
        return allRoles.filter(role => roleNames.includes(role.roleName))
            .map(role => role.roleId)
    }

    return (
        <Box>
            <Autocomplete
                multiple
                id="multi-selector-in-roles"
                options={options}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => onChange(getRoleIdsByRoleNames(newValue))}
                value={getRoleNamesByRoleIds()}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} fullWidth={true} variant="outlined" style={{margin: 5}}
                                                    label={"Rollen"}/>}
            />
        </Box>
    );
};

export default Admin2GetherRolesMultiSelector;
