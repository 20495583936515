import React, {useCallback, useEffect, useRef, useState} from "react";
import {User} from "../../model/User";
import Button from "@material-ui/core/Button";
import {useUserListStyles} from "../../styles/UsersListStyles";
import {Box, IconButton, TextField} from "@material-ui/core";
import {OrgUnit} from "../../model/OrgUnit";
import {checkIsAdmin} from "../../functions/Helpers";
import DeleteOrgUnitsDialog from "./DeleteOrgUnitsDialog";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done'
import WarningDialog from "../../dialogs/WarningDialog";
import {OrgUnitClient} from "../../api/OrgUnitClient";
import SuccessDialog from "../../dialogs/SuccessDialog";
import {
    DataGrid, deDE,
    GridColDef, GridRowModes, GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";

interface Props {
    orgUnits: OrgUnit[]
    reloadOrgUnits: () => Promise<void>
    reloadUsers: () => Promise<void>
    currentUser: User
    signout: () => void
    displayWarning: (message: string) => void
}

enum RowState {
    NORMAL,
    CREATE,
    EDIT
}

const OrgUnitsTable = (props: Props) => {
    const [selectedOrgUnit, setSelectedOrgUnit] = useState<OrgUnit | null>(null)
    const prevSelectedUnit = useRef(selectedOrgUnit);

    const [deleteOrgUnitDialogVisible, setDeleteOrgUnitDialogVisible] = useState<boolean>(false)
    const [deleteOrgUnitSuccessDialogVisible, setDeleteOrgUnitSuccessDialogVisible] = useState<boolean>(false)

    const [newOrgName, setNewOrgName] = useState<string>('')
    const [newOrgNameError, setNewOrgNameError] = useState<string | null>(null)

    const [duplicatedWarning, setDuplicatedWarning] = useState<boolean>(false)

    const [rowState, setRowState] = useState<RowState>(RowState.NORMAL)
    const prevRowStateRef = useRef(RowState.NORMAL);

    const classes = useUserListStyles();

    let [orgUnitsState, setOrgUnitsState] = useState<OrgUnit[]>(props.orgUnits);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const [gridRows, setGridRows] = useState<GridRowsProp>(props.orgUnits.map(o => {
        return {
            id: o.orgId,
            orgName: o.orgName,
            objectRef: o
        }
    }));

    const gridColumns: GridColDef[] = [
        { field: 'orgName', headerName: 'Name', headerAlign: 'center', align: 'center', flex: 1, editable: true,
            renderEditCell: (params) => (
                <div>
                    <TextField
                        data-testid={'inputRow'}
                        fullWidth={false}
                        label={"Name"}
                        type="text"
                        value={newOrgName}
                        onChange={(event) => {
                            setNewOrgName(event.target.value)
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={() => {
                            setNewOrgNameError(null)
                        }}
                        placeholder={params.row.objectRef === null ? 'Name' : params.row.objectRef.orgName}
                        name="new-org-name"
                        required
                        variant='standard'
                        error={newOrgNameError !== null}
                        helperText={newOrgNameError}
                        autoFocus
                    />
                    <IconButton style={{float: 'right',}}
                                data-testid={'closeBtn'}
                                onClick={(e) => handleEditCanceled(params.row.objectRef, e)}>
                        <CloseIcon style={{
                            color: 'red',
                            fontSize: '30pt',
                        }}/></IconButton>
                    <IconButton style={{float: 'right',}}
                                data-testid={'doneBtn'}
                                onClick={(e) => handleEditConfirmed(params.row.objectRef, e)}
                                disabled={shouldAcceptDisabled()}>
                        <DoneIcon style={{
                            color: newOrgName.replace(/\s+/g, '').length <= 0 ? 'gray' : 'green',
                            fontSize: '30pt',
                        }}/></IconButton>
                </div>
            ),
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        }
    ];

    const handleCreateButtonClicked = () => {
        setRowState(RowState.CREATE);
        const id = "newObj";
        setGridRows((prevRows) => [...prevRows,
            {
                id,
                orgName: "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ",
                objectRef: null
            }
        ]);
        setRowModesModel((oldModel) => ({...oldModel,
            [id]: { mode: GridRowModes.Edit },
        }))
        setSelectedOrgUnit(null);
    }

    const handleEditButtonClicked = (o?: OrgUnit) => {
        if (o) {
            setRowState(RowState.EDIT);
            setRowModesModel({ [o.orgId]: { mode: GridRowModes.Edit }});
        }
    }

    const handleEditConfirmed = (o: OrgUnit, e: any) => {
        setSelectedOrgUnit(o);
        if (o) {
            setRowModesModel({ [o.orgId]: { mode: GridRowModes.View, ignoreModifications: true }})
        } else {
            setRowModesModel({ "newObj": { mode: GridRowModes.View, ignoreModifications: true }})
            setGridRows(gridRows.filter(elem => elem.objectRef))
        }
        onDoneEditOrg(e, o);
        setRowState(RowState.NORMAL);
    }

    const handleEditCanceled = (o: OrgUnit, e: any) => {
        setSelectedOrgUnit(o);
        if (o) {
            setRowModesModel({ [o.orgId]: { mode: GridRowModes.View, ignoreModifications: true }})
        } else {
            setRowModesModel({ "newObj": { mode: GridRowModes.View, ignoreModifications: true }})
            setGridRows(gridRows.filter(elem => elem.objectRef))
        }
        setRowState(RowState.NORMAL);
    }

    useEffect(() => {
        setOrgUnitsState(props.orgUnits)
        setGridRows(props.orgUnits.map(o => {
            return {
                id: o.orgId,
                orgName: o.orgName,
                objectRef: o
            }
        }))
    }, [props.orgUnits])

    useEffect(() => {
        const copyOrgUnits = [...orgUnitsState]
        // add dummy data for the new row
        if (prevRowStateRef.current !== RowState.CREATE && rowState === RowState.CREATE) {
            const newOrg = {isDeleted: false, orgId: "", orgName: ""}
            copyOrgUnits.push({...newOrg, orgName: "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ"})
            setOrgUnitsState(copyOrgUnits);
            setSelectedOrgUnit(newOrg)
            setRowState(RowState.CREATE)
        }
        prevRowStateRef.current = rowState;
        prevSelectedUnit.current = selectedOrgUnit;

        // if none selected then row is normal
        if (!selectedOrgUnit && rowState !== RowState.CREATE) {
            setRowState(RowState.NORMAL)
        }
        setNewOrgName('')
    }, [rowState, selectedOrgUnit, orgUnitsState])

    const shouldAcceptDisabled = () => {
        return newOrgName.replace(/\s+/g, '').length <= 0;
    }

    const onDoneEditOrg = (e: any, o: OrgUnit) => {
        let copyOfEntries = props.orgUnits
        setOrgUnitsState(copyOfEntries)
        e.stopPropagation();
        // check if duplicated
        if (props.orgUnits.every((o) => o.orgName.trim() !== newOrgName.trim())) {

            if (o === null) {
                OrgUnitClient.createOrgUnit(newOrgName.trim()).then(() => {
                    props.reloadOrgUnits().then()
                }).finally(() => {
                    props.reloadOrgUnits().then()
                })
            } else
                OrgUnitClient.changeOrgName({
                    orgName: newOrgName.trim(),
                    orgId: o.orgId,
                    isDeleted: false
                }).then(() => {
                    props.reloadOrgUnits().then()
                }).finally(() => {
                    props.reloadOrgUnits().then()
                }).catch(e => alert(e))
            setSelectedOrgUnit(null)
            setRowState(RowState.NORMAL)
        } else {
            setDuplicatedWarning(true)
        }
    }

    const shouldCreateButtonDisabled = () => {
        return rowState !== RowState.NORMAL
    }

    const select = useCallback((orgUnit: OrgUnit) => {
        if (selectedOrgUnit?.orgId === orgUnit.orgId)
            setSelectedOrgUnit(null)
        else
            setSelectedOrgUnit(orgUnit)

    }, [selectedOrgUnit, setSelectedOrgUnit])

    return <div style={{marginBottom: 20, display: "block"}}>
        <WarningDialog text={"Organisation ist bereits im System vorhanden."} isActive={duplicatedWarning}
                       backgroundColor={'#FC4F4F'} horizontalAlignment={"center"} verticalAlignment={"top"}
                       handleWarningDialogClose={() => setDuplicatedWarning(false)}/>
        <SuccessDialog text={"Organisationseinheit ist gelöscht."} isActive={deleteOrgUnitSuccessDialogVisible}
                       horizontalAlignment={"center"} verticalAlignment={"top"}
                       handleSuccessDialogClose={() => setDeleteOrgUnitSuccessDialogVisible(false)}/>
        <DeleteOrgUnitsDialog visible={deleteOrgUnitDialogVisible}
                              selectedOrgUnit={selectedOrgUnit}
                              closeDialog={() => setDeleteOrgUnitDialogVisible(false)}
                              reloadOrgUnits={props.reloadOrgUnits}
                              reloadUsers={props.reloadUsers}
                              didDeleteOrgUnit={() => {
                                  setSelectedOrgUnit(null)
                                  setDeleteOrgUnitSuccessDialogVisible(true)
                              }}
                              displayWarning={props.displayWarning}
                              signout={props.signout}/>
        <div className={"topHeading"} style={{textAlign: "center"}}>
            <h1 style={{marginTop: 8, marginBottom: 8}}> Organisationseinheit</h1>
        </div>
        <div className={classes.list} style={{ textAlign: 'center', margin: "0 40px" }}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'orgName', sort: 'asc' }],
                    },
                }}
                editMode="row"
                isCellEditable={(params) => params.row.objectRef === selectedOrgUnit}
                rowModesModel={rowModesModel}
                rows={gridRows}
                columns={gridColumns}
                onRowClick={(params) => select(params.row.objectRef)}
                rowHeight={41}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#ededed',
                    }
                }}
                rowsPerPageOptions={[]}
                autoHeight={true}
                pagination={undefined}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                experimentalFeatures={{ newEditingApi: true }}
            />
        </div>
        <Box style={{textAlign: 'center', margin: 8}}>
            <Button
                disabled={!checkIsAdmin(props.currentUser) || shouldCreateButtonDisabled()}
                color={"primary"}
                variant={"contained"}
                onClick={() => handleCreateButtonClicked()}
                className={classes.button}
                data-testid={'createBtn'}
            >
                Anlegen
            </Button>
            <Button
                disabled={selectedOrgUnit === null || rowState === RowState.CREATE}
                color={"primary"}
                variant={"contained"}

                onClick={() => handleEditButtonClicked(selectedOrgUnit!)}
                className={classes.button}
                data-testid={'editBtn'}
            >
                Editieren
            </Button>
            <Button
                disabled={selectedOrgUnit === null || rowState === RowState.CREATE}
                color={"primary"}
                variant={"contained"}
                onClick={() => setDeleteOrgUnitDialogVisible(true)}
                className={classes.button}
                data-testid={'deleteBtn'}
            >
                Löschen
            </Button>
        </Box>
    </div>
}


export default OrgUnitsTable
