import {Auth} from "aws-amplify";
import {User} from "../model/User";

export class AclClient {

    private static readonly baseURL = process.env.REACT_APP_BASE_URL;

    static getAccessToken(): Promise<string | undefined> {
        return Auth.currentSession().then(cognitoUserSession => cognitoUserSession.getAccessToken().getJwtToken());
    }

    static async getApplications(): Promise<string[]> {
        const url = AclClient.baseURL + "rest/acl/applications";
        const token = await this.getAccessToken() || "";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            }
        })
        switch (response.status) {
            case 200:
                return response.json()
            default:
                let errorMessage = await response.json() as Error
                return Promise.reject(errorMessage.message)
        }
    }

    static async getApplicationsForUser(editUser: User | null): Promise<string[]> {
        const url = AclClient.baseURL + `rest/acl/applications/${editUser?.username}`;
        const token = await this.getAccessToken() || "";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            }
        })
        switch (response.status) {
            case 200:
                return response.json()
            default:
                let errorMessage = await response.json() as Error
                return Promise.reject(errorMessage.message)
        }
    }
}