import {Autocomplete} from '@material-ui/lab';
import {Box, TextField} from '@material-ui/core';
import React, {useEffect, useState} from "react";
import {AclClient} from "../api/AclClient";

interface ACLSelectorProps {
    onChange: (selected: string[]) => any;
    selected?: string[];
    disabled?: boolean;
}

const ACLMultiSelector: React.FC<ACLSelectorProps> = ({
                                                          onChange,
                                                          selected,
                                                          disabled
                                                      }) => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        AclClient.getApplications().then(apps => setOptions(apps));
    }, []);

    return (
        <Box>
            <Autocomplete
                multiple
                id="multi-selector-in-acl-applications"
                options={options}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => {
                    onChange(newValue);
                }}
                value={selected ?? []}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth={true}
                        variant="outlined"
                        style={{margin: 5}}
                        label={"Zulässige Anwendungen"}
                    />
                )}
            />
        </Box>
    );
};

export default ACLMultiSelector;
