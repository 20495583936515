import React, {useEffect, useState} from "react";
import {User} from "../../model/User";
import {Endpoint, EndpointClient} from "../../api/EndpointClient";
import ConfirmedUsersTable from "./ConfirmedUsersTable";
import {UserClient} from "../../api/UserClient";

interface Props {
    updateUserCredentials: () => Promise<void>
    currentUser: User
    displayWarning: (message: string) => void
    signout: () => void
}

function getEndpointRole(endPoint: Endpoint, user: User): string {
    const admin2getherRoles = endPoint.roles.find(endPointRole => user.roles.some(role => role === endPointRole))
    if (admin2getherRoles !== undefined)
        return admin2getherRoles
    return endPoint.defaultRole
}

const NonAdminUserPage = (props: Props) => {
    const {updateUserCredentials, currentUser} = props;

    const [confirmedUsers, setConfirmedUsers] = useState<User[]>([]);

    useEffect(() => {
        UserClient.getSelf().then(self => setConfirmedUsers([self]))
    }, [setConfirmedUsers])

    const [endpoints, setEndpoints] = useState<Endpoint[]>([]);

    useEffect(() => {
        EndpointClient.getEndPoints().then(endpoints => {
            setEndpoints(endpoints);
        })
    }, [setEndpoints])

    const reloadUsers = () => {
        return Promise.all([
            UserClient.getSelf().then(confirmedUsers => setConfirmedUsers([confirmedUsers])),
        ]).then(() => {
        })
    }

    return (
        <div style={{textAlign: "center"}}>
            <ConfirmedUsersTable
                confirmedUsers={confirmedUsers}
                unconfirmedUserEmailAdresses={[]}
                connectedEnpoints={endpoints}
                connectedAdmin2getherRoles={[]}
                connectedOrgUnits={[]}
                getEndpointRole={getEndpointRole}
                reloadUsers={reloadUsers}
                updateUserCredentials={updateUserCredentials}
                currentUser={currentUser}
                displayWarning={props.displayWarning}
                signout={props.signout}
                isInTestMode={false}
                fetchConfirmedUsersWithFilter={() => new Promise<User[]>(() => {})}/>
        </div>
    );
}

export default NonAdminUserPage
