import {Auth} from "aws-amplify";

export interface Endpoint {
    name: string
    defaultRole: string
    roles: string[]
}

export class EndpointClient {

    private static readonly baseURL = process.env.REACT_APP_BASE_URL;

    static getAccessToken(): Promise<string | undefined> {
        return Auth.currentSession().then(cognitoUserSession => cognitoUserSession.getAccessToken().getJwtToken());
    }

    static async getEndPoints(): Promise<Endpoint[]> {
        const url = EndpointClient.baseURL + "rest/rolesFromOtherServices";
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }
}