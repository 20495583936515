import React, {useCallback, useEffect, useState} from 'react'
import "./AmplifyConfig"
import {getCurrentUser} from "./functions/GetCurrentUser";
import {MainView} from "./views/Pages/MainView";
import {User} from "./model/User";
import {MuiThemeProvider} from "@material-ui/core";
import materna_theme from "./styles/materna_theme";
import {withLogin, WithLoginProps} from "@2gether/frontend-library";

const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

const App = (props: WithLoginProps) => {
    const {signOut} = props;
    const emptyUser: User = {
        username: "",
        email: "",
        givenName: "",
        familyName: "",
        mattermostUsername: "",
        isAdmin: false,
        isMaternaEmployee: false,
        roles: [],
        admin2getherRoles: [],
        applicationACL: [],
        orgUnits: [],
        adminOrgUnits: []
    };
    const [user, setUser] = useState<User>(emptyUser);

    const updateUserCredentials = useCallback(async () => {
        getCurrentUser()
            .then(currentUser => setUser(currentUser))
            .catch(() => signOut());
    }, [setUser, signOut])

    useEffect(() => {
        updateUserCredentials().then();
        const interval = setInterval(() => {
            updateUserCredentials().then();
        }, 60000);
        return () => clearInterval(interval);
    }, [updateUserCredentials]);

    return (
        <MuiThemeProvider theme={materna_theme}>
            <MainView currentUser={user} updateUserCredentials={updateUserCredentials} signOut={signOut}/>
        </MuiThemeProvider>
    );
}

export default withLogin({
    adminsOnly: false,
    requireAcl: false,
    cognitoUrl: "https://" + process.env.REACT_APP_OAUTH_DOMAIN,
    clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    cookieStorage,
})(App);