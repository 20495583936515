import React from "react";
import {MyDataDialog} from "./MyDataDialog";
import {User} from "../../model/User";
import WarningDialog from "../../dialogs/WarningDialog";
import SuccessDialog from "../../dialogs/SuccessDialog";
import {isValidMattermostUsername} from "../../functions/Helpers";
import {UserClient} from "../../api/UserClient";
import {UpdateUserRequest} from "../../model/request/UpdateUserRequest";
import {forceRefreshUser} from "../../functions/GetCurrentUser";

interface Props {
    userData?: User
    updateUserCredentials: () => void
    callbackToUpdateActiveUsers: () => void
    showMyDialog: boolean
    onClose: () => void
    handleSuccess: (successMessage: string) => void
    callbackToUpdateCome2Gether: () => void
}

interface State {
    changeFirstNameInput: string
    changeLastNameInput: string
    changeEMailInput: string
    changeMattermostUsernameInput: string
    firstNameIsInvalid: boolean
    lastNameIsInvalid: boolean
    emailIsInvalid: boolean
    mattermostUsernameIsInvalid: boolean
    showWarningDialog: boolean
    showEmailConfirmDialog: boolean
    warningDialogText: string
}

function isValidMattermostOption(mattermostUsername: string): boolean {
    return mattermostUsername === "" || isValidMattermostUsername(mattermostUsername)
}

function isValidFirstName(firstName: string): boolean {
    return firstName.trim().length > 0 ;
}

function isValidLastName(lastName: string): boolean {
    return lastName.trim().length > 0 ;
}

function isValidEMailAddress(eMail: string): boolean {
    // eslint-disable-next-line no-useless-escape
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(eMail);
}

export default class MyDataComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = ({
            changeFirstNameInput: "",
            changeLastNameInput: "",
            changeEMailInput: "",
            changeMattermostUsernameInput: "",
            firstNameIsInvalid: false,
            lastNameIsInvalid: false,
            emailIsInvalid: false,
            mattermostUsernameIsInvalid: false,
            showWarningDialog: false,
            warningDialogText: "",
            showEmailConfirmDialog: false,
        })
        this.updateSelf.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (!props.userData)
            return;
        this.setState({
            ...this.state,
            changeFirstNameInput: props.userData.givenName,
            changeLastNameInput: props.userData.familyName,
            changeEMailInput: props.userData.email,
            changeMattermostUsernameInput: props.userData.mattermostUsername !== undefined ? props.userData.mattermostUsername : "",
            firstNameIsInvalid: false,
            lastNameIsInvalid: false,
            emailIsInvalid: false,
            mattermostUsernameIsInvalid: false,
            showWarningDialog: false,
            warningDialogText: "",
        })
    }

    render() {
        if (!this.props.userData)
            return null;
        return (
            <div>
                <MyDataDialog
                    showMyDataDialog={this.props.showMyDialog}
                    closeMyDataDialog={this.props.onClose.bind(this)}
                    updateMyDataIfValid={this.updateMyDataIfValid.bind(this)}
                    inputFirstName={this.state.changeFirstNameInput}
                    inputLastName={this.state.changeLastNameInput}
                    inputEMail={this.state.changeEMailInput}
                    inputMattermostUsername={this.state.changeMattermostUsernameInput}
                    changeFirstName={this.changeFirstName.bind(this)}
                    changeLastName={this.changeLastName.bind(this)}
                    changeEMail={this.changeEMail.bind(this)}
                    changeMattermostUsername={this.changeMattermostUsername.bind(this)}
                    hasInvalidFirstName={this.state.firstNameIsInvalid}
                    hasInvalidLastName={this.state.lastNameIsInvalid}
                    hasInvalidEmail={this.state.emailIsInvalid}
                    hasInvalidMattermostUsername={this.state.mattermostUsernameIsInvalid}/>
                <WarningDialog
                    isActive={this.state.showWarningDialog}
                    handleWarningDialogClose={() => this.setState({showWarningDialog: false})}
                    verticalAlignment={'bottom'}
                    horizontalAlignment={'left'}
                    backgroundColor={'#A63232'}
                    text={this.state.warningDialogText.toString()}/>
                <SuccessDialog
                    isActive={this.state.showEmailConfirmDialog}
                    handleSuccessDialogClose={() => this.setState({showEmailConfirmDialog: false})}
                    horizontalAlignment={"center"}
                    verticalAlignment={"bottom"}
                    text={"Eine Bestätigungsmail wurde an die neue Adresse versendet."}/>
            </div>
        );
    }

    changeFirstName = (firstName: string) => {
        this.setState({
            changeFirstNameInput: firstName,
            firstNameIsInvalid: !isValidFirstName(firstName)
        })
    };

    changeLastName = (lastName: string) => {
        this.setState({
            changeLastNameInput: lastName,
            lastNameIsInvalid: !isValidLastName(lastName)
        })
    };

    changeEMail = (eMail: string) => {
        this.setState({
            changeEMailInput: eMail,
            emailIsInvalid: !isValidEMailAddress(eMail)
        })
    };

    changeMattermostUsername = (mattermostUsername: string) => {
        this.setState({
            changeMattermostUsernameInput: mattermostUsername,
            mattermostUsernameIsInvalid: !isValidMattermostOption(mattermostUsername)
        })
    };

    async updateSelf(){
        const updateUserRequest: UpdateUserRequest = {
            username: this.props.userData!.username,
            email: this.state.changeEMailInput,
            givenName: this.state.changeFirstNameInput,
            familyName: this.state.changeLastNameInput,
            mattermostUsername: this.state.changeMattermostUsernameInput,
        }


        await UserClient.updateSelfData(updateUserRequest)
            .then(
                (response) => {
                    console.log({
                        old: this.props.userData!.email,
                        new: this.state.changeEMailInput
                    })
                    if(this.props.userData!.email !== this.state.changeEMailInput){
                        this.setState({
                            showEmailConfirmDialog: true
                        })
                    }

                    this.props.handleSuccess(response)
                    forceRefreshUser()
                    this.props.updateUserCredentials()
                    this.props.callbackToUpdateActiveUsers()
                    this.props.callbackToUpdateCome2Gether()
                })
            .catch((error) => {
                console.log({
                    error: error
                })
                this.setState({
                    warningDialogText: error,
                    showWarningDialog: true
                })
            })
    }

    async updateMyDataIfValid() {
        if (
            isValidFirstName(this.state.changeFirstNameInput) &&
            isValidLastName(this.state.changeLastNameInput) &&
            isValidEMailAddress(this.state.changeEMailInput) &&
            isValidMattermostOption(this.state.changeMattermostUsernameInput)
        ){
            await this.updateSelf()

            if(!this.state.showWarningDialog){
                this.props.onClose()
            }

        } else {
            this.setState({
                showWarningDialog: true,
                warningDialogText: "Mindestens einer Ihrer Eingaben ist nicht valide. Prüfen Sie Ihre Eingaben."
            })
        }
    }
}
