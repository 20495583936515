import React, {useEffect, useState} from "react";
import {User} from "../../model/User";
import {Endpoint, EndpointClient} from "../../api/EndpointClient";
import ConfirmedUsersTable from "./ConfirmedUsersTable";
import UnconfirmedUsersTable from "./UnconfirmedUsersTable";
import {UserClient} from "../../api/UserClient";
import {OrgUnit} from "../../model/OrgUnit";
import OrgUnitsTable from "./OrgUnitsTable";
import {OrgUnitClient} from "../../api/OrgUnitClient";
import {checkIsAdmin} from "../../functions/Helpers";
import {Role} from "../../model/Role";
import {RoleClient} from "../../api/RoleClient";
import RolesTable from "./RolesTable";

interface Props {
    updateUserCredentials: () => Promise<void>
    currentUser: User
    displayWarning: (message: string) => void
    signout: () => void
}

function getEndpointRole(endPoint: Endpoint, user: User): string {
    const admin2getherRoles = endPoint.roles.find(endPointRole => user!.roles!.some(role => role === endPointRole))
    if (admin2getherRoles !== undefined)
        return admin2getherRoles
    return endPoint.defaultRole
}

const UserPage = (props: Props) => {
    const {updateUserCredentials, currentUser} = props;

    const [confirmedUsers, setConfirmedUsers] = useState<User[]>([]);

    const [paginationToken, setPaginationToken] = useState<string>("");

    const fetchLimit = 50;

    const [shouldFetch, setShouldFetch] = useState<boolean>(true);

    const fetchConfirmedUsersPaginated = () => {
        UserClient.getConfirmedUsersWithPagination(fetchLimit, paginationToken).then(res => {
            if (res.paginationToken) {
                setPaginationToken(res.paginationToken);
            } else {
                setPaginationToken("");
                setShouldFetch(false);
            }
            setConfirmedUsers(oldArray => [...oldArray, ...res.data]);
        });
    }

    const fetchConfirmedUsersWithFilter = (filter: any): Promise<User[]> => {
        return UserClient.getConfirmedUsersWithFilter(filter);
    }

    useEffect(() => {
        if (shouldFetch) {
            fetchConfirmedUsersPaginated();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedUsers])

    const [unconfirmedUsers, setUnconfirmedUsers] = useState<User[]>([]);

    useEffect(() => {
        if (checkIsAdmin(currentUser)) {
            UserClient.getUnconfirmedUsers().then(users => setUnconfirmedUsers(users))
        }
    }, [setUnconfirmedUsers])

    const [orgUnits, setOrgUnits] = useState<OrgUnit[]>([]);

    useEffect(() => {
        OrgUnitClient.getOrgUnits().then(units => {
            setOrgUnits(units.filter((unit => unit!.isDeleted ? !unit.isDeleted : !unit.deleted)))
        })
    }, [setOrgUnits])

    const [endpoints, setEndpoints] = useState<Endpoint[]>([]);

    useEffect(() => {
        EndpointClient.getEndPoints().then(endpoints => {
            setEndpoints(endpoints);
        })
    }, [setEndpoints])

    const [admin2getherRoles, setAdmin2getherRoles] = useState<Role[]>([]);

    useEffect(() => {
        RoleClient.getAllRoles().then(roles => {
            setAdmin2getherRoles(roles);
        });
    }, [setAdmin2getherRoles]);

    const reloadUsers = () => {

        if (checkIsAdmin(currentUser)) {
            return Promise.all([
                fetchConfirmedUsersPaginated(),
                UserClient.getUnconfirmedUsers().then(unconfirmedUsers => setUnconfirmedUsers(unconfirmedUsers)),
            ]).then(() => {
            })
        } else {
            return Promise.all([
                fetchConfirmedUsersPaginated()
                //UserClient.getConfirmedUsers().then(confirmedUsers => setConfirmedUsers(confirmedUsers)),
            ]).then(() => {
            })
        }
    }

    const reloadOrgUnits = () => {
        return Promise.all([]).then(() => {
            OrgUnitClient.getOrgUnits().then(units => {
                setOrgUnits(units.filter((unit => unit!.isDeleted ? !unit.isDeleted : !unit.deleted)))
            })
        })
    }

    const reloadRoles = () => {
        return Promise.all([]).then(() => {
            RoleClient.getAllRoles().then(roles => {
                setAdmin2getherRoles(roles);
            })
        })
    }

    const renderUnconfirmedUsersTable = () => {
        if (checkIsAdmin(currentUser)) {
            return <UnconfirmedUsersTable
                unconfirmedUsers={unconfirmedUsers}
                reloadUsers={reloadUsers}
            />
        }
    }

    return (
        <div style={{textAlign: "center"}}>
            {renderUnconfirmedUsersTable()}
            <OrgUnitsTable
                orgUnits={orgUnits}
                reloadOrgUnits={reloadOrgUnits}
                reloadUsers={reloadUsers}
                currentUser={currentUser}
                displayWarning={props.displayWarning}
                signout={props.signout}/>
            <RolesTable roles={admin2getherRoles}
                        reloadRoles={reloadRoles}
                        reloadUsers={reloadUsers}
                        currentUser={currentUser}
                        signout={props.signout}
                        displayWarning={props.displayWarning}/>
            <ConfirmedUsersTable
                confirmedUsers={confirmedUsers}
                unconfirmedUserEmailAdresses={unconfirmedUsers.map(user => user.email)}
                connectedEnpoints={endpoints}
                connectedAdmin2getherRoles={admin2getherRoles}
                connectedOrgUnits={orgUnits}
                getEndpointRole={getEndpointRole}
                reloadUsers={reloadUsers}
                updateUserCredentials={updateUserCredentials}
                currentUser={currentUser}
                displayWarning={props.displayWarning}
                signout={props.signout}
                isInTestMode={false}
                fetchConfirmedUsersWithFilter={fetchConfirmedUsersWithFilter}
            />
        </div>
    );
}

export default UserPage
