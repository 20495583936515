import {Auth} from "aws-amplify";
import {User} from "../model/User";
import {OrgUnit} from "../model/OrgUnit";
import {OrgUnitClient} from "../api/OrgUnitClient";
import {GroupType} from "../model/GroupType";

export async function getCurrentUser() {
    const ADMIN_GROUP = "Admin";
    const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
    const cognitoGroups: string[] = idTokenPayload["cognito:groups"];
    const isAdmin = cognitoGroups.includes(ADMIN_GROUP);
    const isMaternaEmployee: boolean = idTokenPayload["isMaternaEmployee"];

    // Org Units of User in DynamoDB
    const orgUnitsDynamoDB: OrgUnit [] = await getAllOrgUnitsFromDynamoDB();

    // Org Unit Ids as shown in cognito
    const orgUnitsOfUser = await getOrgUnitsOfUserById(idTokenPayload["cognito:username"]);
    const defaultOrgUnits = await getDefaultOrgUnits(orgUnitsOfUser, orgUnitsDynamoDB);
    const adminOrgUnits = await getAdminOrgUnits(orgUnitsOfUser, orgUnitsDynamoDB);
    const roles = getRolesFromCognitoGroups(cognitoGroups);
    const admin2getherRoles: string[] = idTokenPayload["roleId"];

    //TODO: Get Org Units of User -> Get Names of those Org Units -> Split them -> Save them in the User object

    const user: User = {
        username: idTokenPayload["cognito:username"],
        email: idTokenPayload.email,
        givenName: idTokenPayload.given_name,
        familyName: idTokenPayload.family_name,
        mattermostUsername: idTokenPayload["custom:mattermostusername"] || "",
        isAdmin,
        isMaternaEmployee,
        applicationACL: [],
        roles: roles,
        admin2getherRoles: admin2getherRoles,
        orgUnits: defaultOrgUnits,
        adminOrgUnits: adminOrgUnits
    };

    return user;
}

const getRolesFromCognitoGroups = (cognitoGroups: string[]) => {
    let roles: string[];
    roles = cognitoGroups.filter(group => group.startsWith("Sit2Gether"));
    return roles;
}

export const forceRefreshUser = async () => {
    const refreshToken = await Auth.currentSession().then(cognitoUserSession => cognitoUserSession.getRefreshToken());
    const user = await Auth.currentAuthenticatedUser()
    await new Promise(r => user.refreshSession(refreshToken, r));
}

async function getDefaultOrgUnits(orgUnits: GroupType [], orgUnitsDB: OrgUnit[]): Promise<OrgUnit[]> {
    const allOrgUnits: GroupType[] = orgUnits;
    const defaultOrgUnits: OrgUnit[] = []

    allOrgUnits.forEach(orgUnit => {
        if (orgUnit.groupName.startsWith("OrgUnit")) {
            const defaultOrgUnit = {
                deleted: false,
                orgId: orgUnit.groupName,
                orgName: getOrgNameFromDB(orgUnit.groupName, orgUnitsDB),
                isDeleted: false
            }
            defaultOrgUnits.push(defaultOrgUnit)
        }
    })

    return defaultOrgUnits;
}

async function getAdminOrgUnits(orgUnits: GroupType [], orgUnitsDB: OrgUnit[]): Promise<OrgUnit[]> {
    const allOrgUnits: GroupType[] = orgUnits;
    const adminOrgUnits: OrgUnit[] = []

    allOrgUnits.forEach(orgUnit => {
        if (orgUnit.groupName.startsWith("Admin")) {
            const defaultOrgUnit = {
                deleted: false,
                orgId: orgUnit.groupName,
                orgName: getOrgNameFromDB(orgUnit.groupName, orgUnitsDB),
                isDeleted: false
            }
            adminOrgUnits.push(defaultOrgUnit)
        }
    })

    //console.log(adminOrgUnits)
    return adminOrgUnits;
}

async function getOrgUnitsOfUserById(userId: string) {
    return await OrgUnitClient.getOrgUnitsByUserId(userId);
}

async function getAllOrgUnitsFromDynamoDB() {
    return await OrgUnitClient.getOrgUnits();
}

function getOrgNameFromDB(orgId: string, orgUnitsDB: OrgUnit[]): string {
    if (orgId === "Admin") {
        return "Admin2Gether";
    } else if (orgId.startsWith("Admin-")) {
        const orgIdWithoutAdminPrefix = getSubstringWithoutPrefix(orgId)
        const foundOrgUnit = orgUnitsDB.find(orgUnit => orgUnit.orgId === orgIdWithoutAdminPrefix);
        if (foundOrgUnit) {
            return foundOrgUnit.orgName;
        }
    } else {
        const foundOrgUnit = orgUnitsDB.find(orgUnit => orgUnit.orgId === orgId);
        if (foundOrgUnit) {
            return foundOrgUnit.orgName;
        }
    }
    return "";
}


//TODO: Methode wohin auslagern?
function getSubstringWithoutPrefix(input: string): string {
    const prefix = "Admin-";
    if (input.startsWith(prefix)) {
        return input.substring(prefix.length);
    }
    return input;
}