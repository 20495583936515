import {User} from "../model/User";

export function isValidMattermostUsername(s: string): boolean {
    const BOT_NAME = "matterbot"
    const USER_NAME_MAX_LENGTH = 22;
    const USER_NAME_MIN_LENGTH = 3;
    let validUsernameChars = new RegExp(`^[a-z0-9.\\-_]+$`);
    const restrictedUsernames:string[] = [
        "all",
        "channel",
        BOT_NAME,
        "system",
    ];
    return s.length >= USER_NAME_MIN_LENGTH && s.length <= USER_NAME_MAX_LENGTH &&
        validUsernameChars.test(s) &&
        !restrictedUsernames.includes(s);
}

export function checkIsAdmin(user: User): boolean {
    return user.isAdmin;
}

export function checkIsAdminOrOrgUnitAdmin(user: User) : boolean {
    const orgUnit = user.adminOrgUnits.find(orgUnit =>
        user.isAdmin || orgUnit.orgId.startsWith("Admin-"))
    return !!orgUnit;
}

export const isOnlyRead = (currentUser: User, editUser: User | null) => {
    function checkIfOrgAdmin(editUser: User): boolean {
        return currentUser.orgUnits.some(orgUnit =>
            editUser.orgUnits.some(editOrgUnit =>
                editOrgUnit.orgId.localeCompare(orgUnit.orgId) === 0
            )
        );
    }

    if (currentUser.email.localeCompare(editUser?.email ?? "") === 0)
        return false
    else if (currentUser.isAdmin)
        return false;
    else if (editUser !== null)
        return !checkIfOrgAdmin(editUser)
    return false
}