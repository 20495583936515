import React from 'react'
import {ChangePasswordDialog} from "./ChangePasswordDialog";
import WarningSnackbar from "../../dialogs/WarningSnackbar";
import SuccessSnackbar from "../../dialogs/SuccessSnackbar";
import {UserClient} from "../../api/UserClient";
import {ChangePasswordRequest} from "../../model/request/ChangePasswordRequest";
import {isValidPassword} from "@2gether/frontend-library";

interface Props {
    showChangePasswordDialog: boolean
    onClose: () => void
}

interface State {
    firstPassword: string
    secondPassword: string
    previousPassword: string
    showWarningDialog: boolean
    warningDialogText: string
    showSuccessDialog: boolean
    successDialogText: string
}

export default class ChangePasswordComponent extends  React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            firstPassword: "",
            secondPassword: "",
            previousPassword: "",
            showWarningDialog: false,
            warningDialogText: "",
            showSuccessDialog: false,
            successDialogText: ""
        };
    }

    render() {
        return (
            <div>
                <ChangePasswordDialog
                    showChangePasswordDialog={this.props.showChangePasswordDialog}
                    closeChangePasswordDialog={this.closeChangePasswordDialog.bind(this)}
                    changePasswordIfValid={this.changePasswordIfValid}
                    firstPassword={this.state.firstPassword}
                    secondPassword={this.state.secondPassword}
                    previousPassword={this.state.previousPassword}
                    setFirstPassword={this.setFirstPassword.bind(this)}
                    setSecondPassword={this.setSecondPassword.bind(this)}
                    setPreviousPassword={this.setPreviousPassword.bind(this)}
                />
                <WarningSnackbar
                    isActive={this.state.showWarningDialog}
                    handleWarningDialogClose={() => this.setState({showWarningDialog: false})}
                    verticalAlignment={'bottom'}
                    horizontalAlignment={'left'}
                    backgroundColor={'#A63232'}
                    text={this.state.warningDialogText.toString()}
                />
                <SuccessSnackbar
                    text={this.state.successDialogText.toString()}
                    isActive={this.state.showSuccessDialog}
                    horizontalAlignment={'left'}
                    verticalAlignment={'bottom'}
                    handleSuccessDialogClose={() => {this.setState({showSuccessDialog: false})}}/>
            </div>
        )
    }

    changePassword = async () => {
        let changePasswordRequest: ChangePasswordRequest = {
            accessToken: "",
            newPassword: this.state.firstPassword,
            previousPassword: this.state.previousPassword
        }
        try {
            let response = await UserClient.changePassword(changePasswordRequest)
            this.setState({showSuccessDialog: true, successDialogText: response})
            this.closeChangePasswordDialog()
        } catch (err: any) {
            this.setState({showWarningDialog: true, warningDialogText: err.message})
        }
    }

    changePasswordIfValid = async () => {
        if(this.state.firstPassword !== this.state.secondPassword)
            this.setState({
                showWarningDialog: true,
                warningDialogText: "Das wiederholte Passwort muss mit dem eingegebenen übereinstimmen"
            })
        else if (!isValidPassword(this.state.firstPassword))
            this.setState({
                showWarningDialog: true,
                warningDialogText: "Dein Passwort muss aus mindestens 8 Zeichen bestehen und mindestens eine Zahl," +
                    " einen Klein-, Großbuchstaben und ein Sonderzeichen enthalten"
            })
        else {
            this.changePassword()
        }
    }

    setFirstPassword(password: string) {
        this.setState({firstPassword: password})
    }

    setSecondPassword(password: string) {
        this.setState({secondPassword: password})
    }

    setPreviousPassword(password: string) {
        this.setState({previousPassword: password})
    }

    closeChangePasswordDialog = () => {
        this.setState({firstPassword: "", secondPassword: "", previousPassword: ""})
        this.props.onClose()
    }
}
