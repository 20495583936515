import React, {CSSProperties, useCallback, useEffect, useState} from 'react';
import {UserClient} from "../../api/UserClient";
import {Button, CircularProgress, FormControl, Grid, makeStyles, TextField} from "@material-ui/core";
import ACLMultiSelector from "../../components/ACLMultiSelector";
import {withLogin, WithLoginProps} from "@2gether/frontend-library";
import {Route, Router, useHistory} from "react-router-dom";
import {OrgUnit} from "../../model/OrgUnit";
import {emptyOrgUnit} from "../../model/EmptyOrgUnit";
import {OrgUnitClient} from "../../api/OrgUnitClient";
import {Role} from "../../model/Role";
import {RoleClient} from "../../api/RoleClient";
import Admin2GetherRolesMultiSelector from '../../components/Admin2GetherRolesMultiSelector';

export enum ConfirmStatus {
    LOADING,
    CONFIRMED,
    NOT_FOUND,
    INVALID_TOKEN,
    WRONG_PARAMETERS,
    ALREADY_CONFIRMED,
    UNKNOWN
}

const cookieStorage = {
    domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN!,
    secure: false
}

export const parseQuery = (queryString: string) => {
    let query: any = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    pairs.forEach(str => {
        const pair = str.split('=');
        if (pair.length === 2)
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    })
    return query;
}

const useStyles = makeStyles({
    formControl: {
        maxWidth: 400,
        width: '-webkit-fill-available'
    },
    circularProgress: {
        marginLeft: 5
    }
})

const textFieldStyle: CSSProperties = {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10,
    marginBottom: 19,
}

const ConfirmPage = (props: any) => {
    const preselectedApps: string[] = ["Poker2Gether", "Retro2Gether"];
    const classes = useStyles()
    const [status, setStatus] = useState<ConfirmStatus>(ConfirmStatus.LOADING)
    const [applicationACL, setApplicationACL] = useState<string[]>(preselectedApps)
    const [confirmApproved, setConfirmApproved] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [orgUnit, setOrgUnit] = useState<OrgUnit>(emptyOrgUnit)
    const [orgUnits, setOrgUnits] = useState<OrgUnit[]>([])
    const [allRoles, setAllRoles] = useState<Role[]>([])
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])


    useEffect(() => {
        OrgUnitClient.getOrgUnits().then(units => {
            setOrgUnits([emptyOrgUnit, ...units.filter(unit => unit!.isDeleted ? !unit.isDeleted : !unit.deleted)
                .sort((a: OrgUnit, b: OrgUnit) => a.orgName.localeCompare(b.orgName))])
        })
        RoleClient.getAllRoles().then(roles => {
            setAllRoles(roles.sort((role1: Role, role2: Role) => role1.roleName.localeCompare(role2.roleName)))
        })
    }, [])

    useEffect(() => {
        setStatus(ConfirmStatus.LOADING)
    }, [props, setStatus])


    const confirmUser = useCallback(() => {
        if (status !== ConfirmStatus.LOADING)
            return
        const parameters = parseQuery(props.location.search)
        const username = parameters.userId
        const token = parameters.token
        if (username !== undefined && token !== undefined) {
            UserClient.enableUserWithToken(username, token, applicationACL, orgUnit, selectedRoles)
                .then(responseStatus => {
                    setStatus(responseStatus);
                })
                .finally(() => setLoading(false))
        } else {
            setStatus(ConfirmStatus.WRONG_PARAMETERS)
        }
    }, [props, status, setStatus, applicationACL, orgUnit])

    useEffect(() => {
        const username = parseQuery(props.location.search).userId
        UserClient.isConfirmed(username).then(
            (isConfirmed) => {
                if (isConfirmed) {
                    setStatus(ConfirmStatus.ALREADY_CONFIRMED)
                }
                return isConfirmed
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const statusText = useCallback(() => {
        switch (status) {
            case ConfirmStatus.CONFIRMED:
                return "Nutzer wurde bestätigt"
            case ConfirmStatus.LOADING:
                return "Laden"
            case ConfirmStatus.NOT_FOUND:
                return "Fehler: Nutzer ist nicht im System vorhanden"
            case ConfirmStatus.WRONG_PARAMETERS:
                return "Fehler: Falsche Parameter übergeben"
            case ConfirmStatus.INVALID_TOKEN:
                return "Fehler: Token ist nicht gültig"
            case ConfirmStatus.ALREADY_CONFIRMED:
                return "Fehler: Nutzer ist bereits bestätigt"
            case ConfirmStatus.UNKNOWN:
                return "Fehler: Unbekannter Fehler"
        }
    }, [status])

    const confirm = () => {
        setLoading(true)
        setConfirmApproved(true)
        confirmUser()
    }

    const onChange =
        (values: string[]) => setApplicationACL(values)


    return (
        <div style={{margin: 20}}>
            <h2>Nutzer bestätigen</h2>
            {status === ConfirmStatus.LOADING && !confirmApproved ?
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} variant={"outlined"}>
                            <ACLMultiSelector
                                selected={applicationACL}
                                onChange={onChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} variant={"outlined"}>
                            <Admin2GetherRolesMultiSelector
                                onChange={(values: string[]) => setSelectedRoles(values)}
                                allRoles={allRoles}
                                userRoles={selectedRoles}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <TextField
                            data-testid={'orgUnitField'}
                            fullWidth={true}
                            label={"Organisationseinheit"}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            value={orgUnit.orgName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setOrgUnit(orgUnits.find(unit => unit.orgName === event.target.value)!)
                            }}
                            placeholder="Organisationseinheit"
                            name="orgUnit"
                            variant="outlined"
                            style={textFieldStyle}
                            InputLabelProps={{shrink: true}}
                        >
                            {orgUnits.map((unit) => (
                                <option value={unit.orgName} key={unit.orgId}>
                                    {unit.orgName === "" ? "keine" : unit.orgName}
                                </option>))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" disabled={loading} onClick={confirm}>
                            Bestätigen
                            {loading &&
                                <CircularProgress color="inherit" className={classes.circularProgress} size={20}/>}
                        </Button>
                    </Grid>
                </Grid>
                : <div>{statusText()}</div>
            }
        </div>
    );
}


const ConfirmPageWithLogin: React.FC<WithLoginProps> = () => {
    const history = useHistory();
    return <Router history={history}>
        <Route path="/confirm" component={ConfirmPage}/>
    </Router>

}

export default withLogin({
    adminsOnly: false,
    requireAcl: false,
    cognitoUrl: "https://" + process.env.REACT_APP_OAUTH_DOMAIN,
    clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_PUBLIC_URI!,
    adfsIdentityProvider: process.env.REACT_APP_IDENTITY_PROVIDER!,
    cookieStorage,
})(ConfirmPageWithLogin);