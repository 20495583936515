import React from "react";
import Snackbar, {SnackbarOrigin} from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from "@material-ui/core/SnackbarContent";

interface Props {
    text: string;
    isActive: boolean;
    backgroundColor: string;
    horizontalAlignment: SnackbarOrigin["horizontal"];
    verticalAlignment: SnackbarOrigin["vertical"];
    notDisplayCloseIcon?: boolean;

    handleWarningDialogClose(): void;
}

const WarningDialog = (props: Props) => {
    return (
        <div>
            <Snackbar
                className="snackbar"
                anchorOrigin={{
                    vertical: props.verticalAlignment,
                    horizontal: props.horizontalAlignment,
                }}
                open={props.isActive}
                autoHideDuration={6000}
                onClose={props.handleWarningDialogClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: props.backgroundColor,
                    }}
                    message={<span id="message-id" style={{display: "flex", alignItems: "center"}}><ErrorIcon
                        style={{marginRight: 8}}/>{props.text}</span>}
                    action={[
                        <IconButton
                            className="iconbutton"
                            hidden={props.notDisplayCloseIcon}
                            onClick={props.handleWarningDialogClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    )
};

export default WarningDialog
