import {CircularProgress, DialogActions, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, {useCallback, useState} from "react";
import {OrgUnit} from "../../model/OrgUnit";
import {OrgUnitClient} from "../../api/OrgUnitClient";

interface Props {
    visible: boolean
    selectedOrgUnit: OrgUnit | null
    closeDialog: () => void
    reloadOrgUnits: () => void
    reloadUsers: () => void
    didDeleteOrgUnit: () => void
    displayWarning: (message: string) => void
    signout: () => void
}

const DeleteOrgUnitsDialog = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onConfirm = useCallback(() => {
        setIsLoading(true)
        const selectedOrgUnit = props.selectedOrgUnit
        if (selectedOrgUnit !== null)
            OrgUnitClient.deleteOrgUnit(selectedOrgUnit.orgId).then(() => {
                props.reloadOrgUnits()
                props.reloadUsers()
                props.didDeleteOrgUnit()
            }).catch(e => {
                props.displayWarning(e.toString())
            }).finally(() => {
                setIsLoading(false)
                props.closeDialog()
            })
        else
            props.closeDialog()
    }, [props, setIsLoading])

    return (
        <Dialog open={props.visible} fullWidth={true} onEnter={() => setIsLoading(false)}>
            <DialogContent>
                <div>Möchten Sie wirklich "{props.selectedOrgUnit?.orgName ?? "-"}" löschen?</div>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={onConfirm} disabled={isLoading}>
                    Bestätigen {
                    isLoading &&
                    <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}/>
                }
                </Button>
                <Button color={"secondary"} variant={"contained"} onClick={props.closeDialog} disabled={isLoading}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DeleteOrgUnitsDialog;
