import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {User} from "../../model/User";
import {Box, Button, Checkbox, Tooltip} from "@material-ui/core";
import {Endpoint} from "../../api/EndpointClient";
import UserDialog, {DialogState} from "./UserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import {useUserListStyles} from "../../styles/UsersListStyles";
import {forceRefreshUser} from "../../functions/GetCurrentUser";
import {checkIsAdmin} from "../../functions/Helpers";
import {OrgUnit} from "../../model/OrgUnit";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridFilterItem,
    GridCellParams,
    GridColTypeDef,
    GridFilterInputSingleSelect, GridFilterModel, GridFilterInputValue,
    deDE
} from '@mui/x-data-grid';
import {OrgUnitClient} from "../../api/OrgUnitClient";
import {Role} from "../../model/Role";

interface Props {
    confirmedUsers: User[]
    unconfirmedUserEmailAdresses: string[]
    connectedAdmin2getherRoles: Role[]
    connectedEnpoints: Endpoint[]
    connectedOrgUnits: OrgUnit[]
    getEndpointRole: (endpoint: Endpoint, user: User) => string
    reloadUsers: () => Promise<void>
    updateUserCredentials: () => Promise<void>
    currentUser: User
    displayWarning: (message: string) => void
    signout: () => void
    isInTestMode: boolean
    fetchConfirmedUsersWithFilter: (filter: any) => Promise<User[]>
}

export const getOrgNames = (orgUnits: OrgUnit[]) => {
    const orgNames = ((Array.isArray(orgUnits)) ? orgUnits : [])
        .filter(orgUnit => !!orgUnit)
        .filter(orgUnit => !orgUnit.deleted)
        .map(orgUnit => orgUnit.orgName)
    return orgNames.length > 0 ? orgNames : ["keine"]
}

const ConfirmedUsersTable = (props: Props) => {

    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.CLOSED)
    const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState<boolean>(false)
    const [copyOfConfirmedUsers, setCopyOfConfirmedUsers] = useState<User[]>(props.confirmedUsers);
    const [filteredConfirmedUsers, setFilteredConfirmedUsers] = useState<User[]>([]);

    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<any>({});

    const [allAdminOrgUnits, setAllAdminOrgUnits] = useState<OrgUnit[]>([]);

    useEffect(() => {
        const fetchAllAdminOrgUnits = async () => {
            try {
                const orgUnits = await OrgUnitClient.getOrgUnits();
                setAllAdminOrgUnits(orgUnits);
            } catch (error) {
                // Handle error fetching org units
                console.error("Error fetching org units:", error);
            }
        };

        fetchAllAdminOrgUnits();
    }, []);

    const fetchFilteredData = () => {
        props.fetchConfirmedUsersWithFilter(filterOptions).then(users => setFilteredConfirmedUsers(users));
    }

    useEffect(() => {
        setCopyOfConfirmedUsers(props.confirmedUsers);
    }, [props.confirmedUsers])

    useEffect(() => {
        if(filterOptions.filterModel &&
            filterOptions.filterModel.items.length > 0 &&
            filterOptions.filterModel.items[0].value &&
            filterOptions.filterModel.items[0].value !== ""
        ) {
            fetchFilteredData();
            setIsFilterActive(true);
        } else {
            setFilteredConfirmedUsers([]);
            setIsFilterActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions, dialogState, deleteUserDialogVisible])

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model
        setFilterOptions({ filterModel: { ...filterModel } });
    }, []);

    const classes = useUserListStyles();

    // used to sort an array alphanumeric for the display of orgUnitNames
    const sortAlphaNum = (a: string, b: string) => a.localeCompare(b, 'en', {numeric: true});

    const orgUnitFilterFn = (filterItem: GridFilterItem)=> {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue)
            return null;

        return (params: GridCellParams): boolean => {
            if (filterItem.value === "keine")
                return params.value.filter((o: any) => o).length === 0;
            return params.value.filter((o: any) => o).some((o: OrgUnit) => o.orgName === filterItem.value);
        };
    }

    const operators = [
        {
            label: "has",
            value: "has",
            getApplyFilterFn: orgUnitFilterFn,
            InputComponent: GridFilterInputSingleSelect
        }
    ];

    const isOperator = [
        {
            label: "is",
            value: "is",
            getApplyFilterFn: () => null,
            InputComponent: GridFilterInputSingleSelect
        }
    ]

    const startsWithOperator = [
        {
            label: "starts with",
            value: "starts with",
            getApplyFilterFn: () => null,
            InputComponent: GridFilterInputValue
        }
    ]

    const orgUnitColumnTypeDef: GridColTypeDef = {
        type: 'singleSelect',
        valueOptions: props.connectedOrgUnits.map((o: OrgUnit) => o.orgName).concat("keine")
    };

    function mapUserToRowData(user: User) {
        return {
            id: user.username,
            familyName: user.familyName,
            givenName: user.givenName,
            email: user.email,
            isAdmin: user.isAdmin,
            isMaternaEmployee: user.isMaternaEmployee,
            orgUnit: user.orgUnits,
            orgUnitAdmin: user.adminOrgUnits.length > 0,
            sit2getherRole: props.getEndpointRole(props.connectedEnpoints.find(e => e.name === "Sit2Gether")!, user),
            objectRef: user
        }
    }

    const gridRows: GridRowsProp = isFilterActive ?
        filteredConfirmedUsers.map(mapUserToRowData)
        :
        copyOfConfirmedUsers.map(mapUserToRowData);

    const gridColumns: GridColDef[] = [
        { field: 'familyName', headerName: 'Name', headerAlign: 'center', align: 'center', flex: 1,
            filterOperators: startsWithOperator,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'givenName', headerName: 'Vorname', headerAlign: 'center', align: 'center', flex: 1,
            filterOperators: startsWithOperator,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'email', headerName: 'E-Mail-Adresse', headerAlign: 'center', align: 'center', flex: 1,
            filterOperators: startsWithOperator,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },

        { field: 'isMaternaEmployee', headerName: 'Materna', headerAlign: 'center', align: 'center', type: "boolean",
            renderCell: (params) => (
                <Checkbox
                    data-testid="materna-checkbox"
                    checked={params.value}
                    readOnly={true}
                    disableTouchRipple={true}
                    disabled={true}
                />
            ),
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },

        { field: 'isAdmin', headerName: 'Admin', headerAlign: 'center', align: 'center', type: "boolean",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    readOnly={true}
                    disableTouchRipple={true}
                    disabled={true}
                />
            ),
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },

        { field: 'orgUnitAdmin', headerName: 'Org-Einheiten-Admin', headerAlign: 'center', align: 'center', type: "boolean", flex: 1,
            renderCell: (params) => (
                <Checkbox
                    data-testid="org-unit-checkbox"
                    checked={params.value}
                    readOnly={true}
                    disableTouchRipple={true}
                    disabled={true}
                />
            ),
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'orgUnit', headerName: 'Organisationseinheit', headerAlign: 'center', align: 'center', flex: 1,
            ...orgUnitColumnTypeDef,
            filterOperators: operators,
            renderCell: (params) => {
                const rowOrgUnits = params.value.filter((o: any) => o).sort((o1: any, o2: any) => sortAlphaNum(o1.orgName, o2.orgName));
                let orgUnitShortString = "keine";
                if(params.value.length > 0) {
                    if(rowOrgUnits.length === 1) {
                        if(!rowOrgUnits[0].isDeleted)
                            orgUnitShortString = rowOrgUnits[0].orgName;
                    }
                    else if(rowOrgUnits.length > 1)
                        orgUnitShortString = rowOrgUnits[0].orgName + " [...]";
                }
                const orgUnitString = rowOrgUnits.map((o: any) => o.orgName).slice(1).join(", ");
                return (
                    <Tooltip title={orgUnitString}>
                        <div>{orgUnitShortString}</div>
                    </Tooltip>
                )
            },
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'sit2getherRole', headerName: 'Sit2Gether-Rolle', headerAlign: 'center', align: 'center', flex: 1,
            type: "singleSelect",
            valueOptions: ["Sit2Gether-Standardnutzer", "Sit2Gether-Admin"],
            filterOperators: isOperator,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        }
    ];


    const select = useCallback((user: User) => {
        if (selectedUser?.username === user.username)
            setSelectedUser(null)
        else
            setSelectedUser(user)
    }, [selectedUser, setSelectedUser])

    const [windowHeight, setWindowHeight] = useState<number>(0)

    useLayoutEffect(() => {
        function resizeOccured() {
            setWindowHeight(window.innerHeight)
        }

        window.addEventListener('resize', resizeOccured);
        resizeOccured();
        return () => window.removeEventListener('resize', resizeOccured);
    }, [setWindowHeight]);

    return (
        <div>
            <UserDialog
                dialogState={dialogState}
                allUserEmailAdresses={props.confirmedUsers.map(user => user.email).concat(props.unconfirmedUserEmailAdresses)}
                close={() => {
                    setDialogState(DialogState.CLOSED)
                }}
                userAdded={() => props.reloadUsers()}
                reloadData={props.reloadUsers}
                userChanged={async (user) => {
                    if (user.username === props.currentUser.username) {
                        await forceRefreshUser();
                        await props.updateUserCredentials();
                    }
                    setSelectedUser(user)
                    await props.reloadUsers()
                }}
                editUser={dialogState === DialogState.EDIT ? selectedUser : null}
                connectedEnpoints={props.connectedEnpoints}
                admin2getherRoles={props.connectedAdmin2getherRoles}
                connectedOrgUnits={props.connectedOrgUnits}
                allAdminOrgUnits={allAdminOrgUnits}
                getEndpointRole={props.getEndpointRole}
                currentUser={props.currentUser}
            />
            <DeleteUserDialog
                visible={deleteUserDialogVisible}
                selectedUser={selectedUser}
                closeDialog={() => setDeleteUserDialogVisible(false)}
                displayWarning={props.displayWarning}
                reloadUsers={props.reloadUsers}
                didDeleteUser={() => setSelectedUser(null)}
                signout={props.signout}
            />
            <div className={"topHeading"} style={{textAlign: "center"}}>
                <h1 style={{marginTop: 8, marginBottom: 8}}>Nutzer</h1>
            </div>
            <div className={classes.list} style={{ height: Math.max(300, (windowHeight - 206)), textAlign: 'center', margin: "0 40px" }}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'familyName', sort: 'asc' }],
                        },
                    }}
                    pageSize={50}
                    rows={gridRows}
                    columns={gridColumns}
                    rowsPerPageOptions={[]}
                    onRowClick={(params) => select(params.row.objectRef)}
                    rowHeight={41}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#ededed',
                        }
                    }}
                    pagination
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    disableVirtualization={props.isInTestMode}
                />
            </div>
            <Box style={{textAlign: 'center', margin: 8}}>
                <Button
                    disabled={!checkIsAdmin(props.currentUser)}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => setDialogState(DialogState.CREATE)}
                    className={classes.button}
                >
                    Anlegen
                </Button>
                <Button
                    disabled={selectedUser === null}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        setDialogState(DialogState.EDIT)
                    }}
                    className={classes.button}
                >
                    Editieren
                </Button>
                <Button
                    disabled={selectedUser === null}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        setDeleteUserDialogVisible(true)
                    }}
                    className={classes.button}
                >
                    Löschen
                </Button>
            </Box>
        </div>
    );
}

export default ConfirmedUsersTable
