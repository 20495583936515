import React, {useCallback, useEffect, useState} from 'react';
import {UserClient} from "../../api/UserClient";
import {parseQuery} from "./ConfirmPage";

export enum DenyStatus {
    LOADING,
    ALREADY_CONFIRMED,
    DENIED,
    INVALID_TOKEN,
    WRONG_PARAMETERS,
    NOT_FOUND,
    UNKNOWN
}

const DenyPage = (props: any) => {
    const [status, setStatus] = useState<DenyStatus>(DenyStatus.LOADING)

    useEffect(() => {
        setStatus(DenyStatus.LOADING)
    }, [props, setStatus])

    useEffect(() => {
        if (status !== DenyStatus.LOADING)
            return
        const parameters = parseQuery(props.location.search)
        const username = parameters.userId
        const token = parameters.token
        if (username !== undefined && token !== undefined) {
            UserClient.deleteUserWithToken(username, token).then(responseStatus => setStatus(responseStatus))
        } else {
            setStatus(DenyStatus.WRONG_PARAMETERS)
        }
    }, [props, status, setStatus])

    const statusText = useCallback(() => {
        switch (status) {
            case DenyStatus.ALREADY_CONFIRMED:
                return "Fehler: Nutzer wurde bereits bestätigt"
            case DenyStatus.LOADING:
                return "Laden"
            case DenyStatus.DENIED:
                return "Nutzer wurde abgelehnt"
            case DenyStatus.WRONG_PARAMETERS:
                return "Fehler: Falsche Parameter übergeben"
            case DenyStatus.INVALID_TOKEN:
                return "Fehler: Token ist nicht gültig"
            case DenyStatus.NOT_FOUND:
                return "Fehler: Nutzer ist nicht im System vorhanden"
            case DenyStatus.UNKNOWN:
                return "Fehler: Unbekannter Fehler"
        }
    }, [status])

    return (
        <div style={{margin: 20}}>
            <h2>Nutzer ablehnen</h2>
            <div>{statusText()}</div>
        </div>
    );
}

export default DenyPage;