import {CircularProgress, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, {useCallback, useState} from "react";
import {User} from "../../model/User";
import {UserClient} from "../../api/UserClient";

interface Props {
    visible: boolean
    selectedUser: User | null
    closeDialog: () => void
    reloadUsers: () => void
    didDeleteUser: () => void
    displayWarning: (message: string) => void
    signout: () => void
}

const DeleteUserDialog = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const title = "Möchten sie den Nutzer wirklich löschen?"

    const onConfirm = useCallback(() => {
        setIsLoading(true)
        const selectedUser = props.selectedUser
        if (selectedUser !== null)
            UserClient.moveUserToDeletedPool(selectedUser.username).then((response) => {
                // When a user deletes himself, he is subsequently logged out
                if (response === "Eigener Nutzer wurde gelöscht") {
                    props.signout();
                }
                props.reloadUsers()
                props.didDeleteUser()
            }).catch(e => {
                props.displayWarning(e.toString())
            }).finally(() => {
                setIsLoading(false)
                props.closeDialog()
            })
        else
            props.closeDialog()
    }, [props, setIsLoading])

    return (
        <Dialog open={props.visible} fullWidth={true} onEnter={() => setIsLoading(false)}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <div>Vorname: {props.selectedUser?.givenName ?? "-"}</div>
                <div>Nachname: {props.selectedUser?.familyName ?? "-"}</div>
                <div>E-Mail: {props.selectedUser?.email ?? "-"}</div>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={onConfirm} disabled={isLoading}>
                    Bestätigen {
                    isLoading &&
                    <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}/>
                }
                </Button>
                <Button color={"secondary"} variant={"contained"} onClick={props.closeDialog} disabled={isLoading}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DeleteUserDialog;
