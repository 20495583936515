import React, {useEffect} from 'react'
import {HomePageView} from "./HomePageView";
import {User} from "../../model/User";

interface Props {
    signOut: () => void
    currentUser: User
    updateUserCredentials: () => Promise<void>
}

export const MainView = (props: Props) => {
    const {currentUser, updateUserCredentials, signOut} = props;

    useEffect(() => {
        if (currentUser.username === "")
            updateUserCredentials().then()
    }, [currentUser, updateUserCredentials])

    if (currentUser.username === "")
        return <div/>

    return <HomePageView currentUser={currentUser} signOut={signOut} updateUserCredentials={updateUserCredentials}/>
}
