import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useChangePasswordStyles} from "../../styles/ChangePasswordStyles";

interface Props {
    showChangePasswordDialog: boolean
    closeChangePasswordDialog: () => void
    changePasswordIfValid: () => void
    firstPassword: string
    secondPassword: string
    previousPassword: string
    setFirstPassword: (password: string) => void
    setSecondPassword: (password: string) => void
    setPreviousPassword: (password: string) => void
}

export const ChangePasswordDialog: React.FC<Props> = (props: Props) => {
    const {
        showChangePasswordDialog,
        closeChangePasswordDialog,
        changePasswordIfValid,
        firstPassword,
        secondPassword,
        previousPassword,
        setFirstPassword,
        setSecondPassword,
        setPreviousPassword
    } = props;

    const classes = useChangePasswordStyles();

    return (
        <Dialog open={showChangePasswordDialog}>
            <DialogTitle>Passwort ändern</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Altes Passwort"
                    type="password"
                    autoComplete="current-password"
                    value={previousPassword}
                    onChange={event => setPreviousPassword(event.target.value)}
                    className={classes.inputFiled}
                />
                <TextField
                    label="Neues Passwort"
                    type="password"
                    autoComplete='new-password'
                    value={firstPassword}
                    onChange={event => setFirstPassword(event.target.value)}
                    className={classes.inputFiled}
                />
                <TextField
                    label="Neues Passwort (Wiederholung)"
                    type="password"
                    autoComplete='new-password'
                    value={secondPassword}
                    onChange={event => setSecondPassword(event.target.value)}
                    className={classes.inputFiled}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={changePasswordIfValid}
                        color="primary" variant={"contained"}>
                    Speichern
                </Button>
                <Button onClick={closeChangePasswordDialog}
                        color={"secondary"} variant={"contained"}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}