import {UserClient} from "./UserClient";
import {Role} from "../model/Role";
import {CreateRoleRequest} from "../model/request/CreateRoleRequest";
import {EditRoleRequest} from "../model/request/EditRoleRequest";

export class RoleClient {

    private static readonly baseURL = process.env.REACT_APP_BASE_URL;

    static async getAllRoles(): Promise<Role[]> {
        const url = RoleClient.baseURL + "rest/roles";
        const token = await UserClient.getAccessToken() || "";
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        });
        return this.handleResponse(response);
    }

    static async createRole(createRoleRequest: CreateRoleRequest): Promise<Role> {
        const url = RoleClient.baseURL + "rest/roles";
        const token = await UserClient.getAccessToken() || "";
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(createRoleRequest),
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            }
        });
        return this.handleResponse(response);
    }

    static async editRoleById(id: String, editRoleRequest: EditRoleRequest): Promise<void> {
        const url = RoleClient.baseURL + "rest/roles/" + id;
        const token = await UserClient.getAccessToken() || "";
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(editRoleRequest)
        });
        return this.handleResponse(response);
    }

    static async deleteRoleById(roleId: string): Promise<void> {
        const url = RoleClient.baseURL + "rest/roles/" + roleId;
        const token = await UserClient.getAccessToken() || "";
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            }
        });
        return this.handleResponse(response);
    }

    static async handleResponse(response: Response) {
        if (response.ok) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        } else {
            let errorMessage = await response.text();
            let message = `Error ${response.status} ${response.statusText}: ${errorMessage}`;
            switch (response.status) {
                case 400:
                    message = `${response.status} BAD REQUEST: ${errorMessage || ' Role Name should not be longer than 60 characters.'}`;
                    break;
                case 404:
                    message = `${response.status} NOT FOUND: ${errorMessage || 'The requested resource could not be found.'}`;
                    break;
                case 409:
                    message = `${response.status} CONFLICT: ${errorMessage || 'The request could not be completed due to a conflict.'}`;
                    break;
                case 500:
                    message = `${response.status} INTERNAL SERVER ERROR: ${errorMessage || 'An unexpected error occurred on the server.'}`;
                    break;
            }
            console.error(response);
            throw new Error(message);
        }
    }

}
