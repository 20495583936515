import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {useChangePasswordStyles} from "../../styles/ChangePasswordStyles";

interface Props {
    showMyDataDialog: boolean
    closeMyDataDialog: () => void
    updateMyDataIfValid: () => void
    inputFirstName: string
    inputLastName: string
    inputEMail: string
    inputMattermostUsername: string
    changeFirstName: (firstName: string) => void
    changeLastName: (lastName: string) => void
    changeEMail: (eMail: string) => void
    changeMattermostUsername: (mattermostUsername: string) => void
    hasInvalidFirstName: boolean
    hasInvalidLastName: boolean
    hasInvalidEmail: boolean
    hasInvalidMattermostUsername: boolean
}

export const MyDataDialog: React.FC<Props> = (props: Props) => {
    const {
        showMyDataDialog,
        closeMyDataDialog,
        updateMyDataIfValid,
        inputFirstName,
        inputLastName,
        inputEMail,
        changeFirstName,
        changeLastName,
        changeEMail,
        hasInvalidFirstName,
        hasInvalidLastName,
        hasInvalidEmail
    } = props
    const classes = useChangePasswordStyles();
    return (
        <div>
            <Dialog open={showMyDataDialog}>
                <DialogTitle>Meine Daten</DialogTitle>
                <DialogContent>
                    <div style={{ padding: 0 }}>
                    <TextField
                        autoFocus
                        required
                        label="Vorname"
                        type="text"
                        variant="outlined"
                        style={{margin: 5}}
                        value={inputFirstName}
                        onChange={event => changeFirstName(event.target.value)}
                        className={classes.inputFiled}
                        inputProps={{maxLength: 30}}
                        error={hasInvalidFirstName}
                    />
                    <span style={{color: "red", display: hasInvalidFirstName ? "unset" : "none"}}>Diese Eingabe ist ungültig</span>
                    <TextField
                        required
                        label="Nachname"
                        type="text"
                        variant="outlined"
                        style={{margin: 5}}
                        value={inputLastName}
                        onChange={event => changeLastName(event.target.value)}
                        className={classes.inputFiled}
                        inputProps={{maxLength: 30}}
                        error={hasInvalidLastName}
                    />
                    <span style={{color: "red", display: hasInvalidLastName ? "unset" : "none"}}>Diese Eingabe ist ungültig</span>
                    <TextField
                        required
                        label="E-Mail"
                        type="text"
                        variant="outlined"
                        style={{margin: 5}}
                        value={inputEMail}
                        onChange={event => changeEMail(event.target.value)}
                        className={classes.inputFiled}
                        inputProps={{maxLength: 100}}
                        error={hasInvalidEmail}
                    />
                    <span style={{
                        color: "red",
                        display: hasInvalidEmail ? "unset" : "none"
                    }}>Diese Eingabe ist ungültig</span>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={updateMyDataIfValid}
                            color="primary" variant={"contained"}>
                        Speichern
                    </Button>
                    <Button onClick={closeMyDataDialog}
                            color={"secondary"} variant={"contained"}>
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
