import React from "react";
import App from "./App";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ConfirmPage from "./views/Pages/ConfirmPage";
import DenyPage from "./views/Pages/DenyPage";

const AppWithRouter = () => {
    return <Router>
        <div>
            <Switch>
                <Route path="/confirm" component={ConfirmPage}/>
                <Route path="/deny" component={DenyPage}>
                </Route>
                <Route path="/">
                    <App/>
                </Route>
            </Switch>
        </div>
    </Router>
}

export default AppWithRouter