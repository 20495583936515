import Snackbar, {SnackbarOrigin} from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

interface Props {
    text: string;
    isActive: boolean;
    horizontalAlignment: SnackbarOrigin["horizontal"];
    verticalAlignment: SnackbarOrigin["vertical"];
    handleSuccessDialogClose(): void;
}

const SuccessDialog = (props: Props) => {
    return (
        <div>
            <Snackbar
                className="snackbar"
                anchorOrigin={{
                    vertical: props.verticalAlignment,
                    horizontal: props.horizontalAlignment,
                }}
                open={props.isActive}
                autoHideDuration={7000}
                onClose={props.handleSuccessDialogClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: "green",
                    }}
                    message={
                        <span id="message-id" style={{display: "flex", alignItems: "center"}}><SuccessIcon style={{marginRight:8}}/> {props.text}</span>}
                    action={[
                        <IconButton
                            className="iconbutton"
                            onClick={props.handleSuccessDialogClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    )
};


export default SuccessDialog
