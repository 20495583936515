import {UserClient} from "./UserClient";
import {OrgUnit} from "../model/OrgUnit";
import {GroupType} from "../model/GroupType";

export class OrgUnitClient {

    private static readonly baseURL = process.env.REACT_APP_BASE_URL;

    static async getOrgUnits(): Promise<OrgUnit[]> {
        const url = OrgUnitClient.baseURL + "rest/org-units";
        const token = await UserClient.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert('Error ' + res.status + ' ' + res.statusText)
            }
            return res.json()
        }).catch(e => console.log(e.message))
    }

    static async getOrgUnitsByUserId(id: string): Promise<GroupType[]> {
        const url = OrgUnitClient.baseURL + "rest/org-units/" + id;
        const token = await UserClient.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert('Error ' + res.status + ' ' + res.statusText)
                console.log(res)
            }
            return res.json()
        }).catch(e => console.log(e.message))
    }


    static async createOrgUnit(orgName: string): Promise<OrgUnit> {
        // console.log("baseURL="+this.baseURL);
        const url = OrgUnitClient.baseURL + "rest/org-units";
        const token = await UserClient.getAccessToken() || "";
        return fetch(url, {
            method: "POST",
            body: orgName,
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                if (res.status === 400) {
                    alert('Error ' + res.status + ' BAD REQUEST: Name must not contains whitespaces')
                } else
                    alert('Error ' + res.status + ' ' + res.statusText)
                console.log(res)
            }
            return res.json()
        }).catch(e => console.log(e.message))
    }


    static async changeOrgName(orgUnit: OrgUnit): Promise<OrgUnit> {
        // console.log("baseURL="+this.baseURL);
        const url = OrgUnitClient.baseURL + "rest/org-units/" + orgUnit.orgId;
        const token = await UserClient.getAccessToken() || "";
        return fetch(url, {
            method: "PUT",
            body: orgUnit.orgName,
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            if (res.status !== 200) {
                if (res.status === 400) {
                    alert('Error ' + res.status + ' BAD REQUEST: Name must not contains whitespaces')
                } else
                    alert('Error ' + res.status + ' ' + res.statusText)
                console.log(res)
            }
            return res.json()
        }).catch(e => console.log(e.message))
    }


    static async deleteOrgUnit(orgId: String): Promise<OrgUnit> {
        console.log("baseURL=" + this.baseURL);
        const url = OrgUnitClient.baseURL + "rest/org-units/" + orgId;
        const token = await UserClient.getAccessToken() || "";
        return fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json()).catch(e => console.log(e.message))
    }
}
