import {makeStyles} from "@material-ui/core";

export const useUserListStyles = makeStyles({
    button: {
        margin: "5px",
        minWidth: "110px"
    },
    list: {
        overflow: 'auto'
    },
});
