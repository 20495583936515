import {Autocomplete} from '@material-ui/lab';
import {Box, TextField} from '@material-ui/core';
import React from "react";
import {OrgUnit} from "../model/OrgUnit";

interface AdminRightsMultiSelectorProps {
    onChange: (selected: OrgUnit[]) => void;
    selected?: OrgUnit[];
    disabled?: boolean;
    orgUnits: OrgUnit[];
}

const AdminRightsMultiSelector: React.FC<AdminRightsMultiSelectorProps> = ({
                                                                               onChange,
                                                                               selected,
                                                                               orgUnits,
                                                                               disabled
                                                                           }) => {
    let options: string[] = orgUnits.map(u => u.orgName);

    const getOrgNames = () => {
        return (selected ?? []).map(a => a.orgName);
    }

    const getOrgUnitsByName = (orgNames: string[]) => {
        return orgNames.map(name => orgUnits.find(u => u.orgName === name))
            .filter(e => e !== undefined) as OrgUnit[];
    }

    return (
        <Box>
            <Autocomplete
                multiple
                id="multi-selector-in-admin-rights"
                options={options}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => onChange(getOrgUnitsByName(newValue))}
                value={getOrgNames()}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} fullWidth={true} variant="outlined" style={{margin: 5}}
                                                    label={"Administrationsrechte für:"}/>}
            />
        </Box>
    );
};

export default AdminRightsMultiSelector;
