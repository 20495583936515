import React, {useCallback, useState} from "react";
import {User} from "../../model/User";
import Button from "@material-ui/core/Button";
import {UserClient} from "../../api/UserClient";
import {useUserListStyles} from "../../styles/UsersListStyles";
import {CircularProgress} from "@material-ui/core";
import {DataGrid, deDE, GridColDef, GridRowsProp} from "@mui/x-data-grid";

interface Props {
    unconfirmedUsers: User[]
    reloadUsers: () => Promise<void>
}

const UnconfirmedUsersTable = (props: Props) => {

    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false)
    const [isLoadingDeny, setIsLoadingDeny] = useState<boolean>(false)

    const classes = useUserListStyles();

    const gridRows: GridRowsProp = props.unconfirmedUsers.map(u => {
        return {
            id: u.username,
            name: u.familyName,
            firstname: u.givenName,
            email: u.email,
            objectRef: u
        }
    });

    const gridColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', headerAlign: 'center', align: 'center', flex: 1,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'firstname', headerName: 'Vorname', headerAlign: 'center', align: 'center', flex: 1,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
        { field: 'email', headerName: 'E-Mail-Adresse', headerAlign: 'center', align: 'center', flex: 1,
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        },
    ];

    const select = useCallback((user: User) => {
        if (selectedUser?.username === user.username)
            setSelectedUser(null)
        else
            setSelectedUser(user)
    }, [selectedUser,setSelectedUser])

    if (props.unconfirmedUsers.length === 0)
        return null;

    return (
        <div style={{marginBottom: 20}}>
            <div className={"topHeading"} style={{textAlign: "center"}}>
                <h1 style={{marginTop: 8, marginBottom: 8}}>Nicht bestätigte Nutzer</h1>
            </div>
            <div className={classes.list} style={{ textAlign: 'center', margin: "0 40px" }}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                    }}
                    rows={gridRows}
                    columns={gridColumns}
                    onRowClick={(params) => select(params.row.objectRef)}
                    rowHeight={41}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#ededed',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            visibility: 'hidden',
                        }
                    }}
                    rowsPerPageOptions={[]}
                    autoHeight={true}
                    pagination={undefined}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
            <div style={{margin: "10px 10px 0px 0px", textAlign: "center"}}>
                <Button
                    disabled={selectedUser === null || isLoadingConfirm || isLoadingDeny}
                    className={classes.button}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        setIsLoadingConfirm(true)
                        if (selectedUser !== null) {
                            UserClient.enableUser(selectedUser).then(() => {
                                props.reloadUsers().then(() => {
                                    setSelectedUser(null)
                                    setIsLoadingConfirm(false)
                                })
                            })
                        }
                    }}>
                    Freischalten
                    {isLoadingConfirm &&
                    <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}
                    />}
                </Button>
                <Button
                    disabled={selectedUser === null || isLoadingConfirm || isLoadingDeny}
                    className={classes.button}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        setIsLoadingDeny(true)
                        if (selectedUser !== null) {
                            UserClient.deleteUser(selectedUser).then(() => {
                                props.reloadUsers().then(() => {
                                    setSelectedUser(null)
                                    setIsLoadingDeny(false)
                                })
                            })
                        }
                    }}>
                    Ablehnen
                    {isLoadingDeny &&
                    <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}
                    />}
                </Button>
            </div>
        </div>
    );
}

export default UnconfirmedUsersTable
