import {Autocomplete} from '@material-ui/lab';
import {Box, TextField} from '@material-ui/core';
import React from "react";
import {OrgUnit} from "../model/OrgUnit";
import {User} from "../model/User";
import {addRemovedOrgUnits, getOrgNames, isVisibleOrgUnitToUser} from "./ComponentUtils/FilterOperators";

interface OrgUnitSelectorProps {
    onChange: (selected: OrgUnit[]) => void;
    selected?: OrgUnit[];
    disabled?: boolean;
    editUser?: User;
    orgUnits: OrgUnit[];
    user: User;
}

const OrgUnitMultiSelector: React.FC<OrgUnitSelectorProps> = ({
                                                                  onChange,
                                                                  selected,
                                                                  orgUnits,
                                                                  disabled,
                                                                  editUser,
                                                                  user
                                                              }) => {
    const removedSelectedOrgUnitNames: string[] = getOrgNames(selected).filter(a => !isVisibleOrgUnitToUser(user, a, editUser));
    let selectedOrgUnitNames: string[] = getOrgNames(selected).filter(a => isVisibleOrgUnitToUser(user, a, editUser));
    let options: string[] = orgUnits.map(a => a.orgName).filter(a => isVisibleOrgUnitToUser(user, a, editUser));

    const updateOrgUnits = (selectedNames: string[]) => {
        const selectedOrgUnits: OrgUnit[] = selectedNames.map(name => orgUnits.find(u => u.orgName === name))
            .filter(e => e !== undefined) as OrgUnit[];
        user.adminOrgUnits.length !== 0 ? onChange(
                addRemovedOrgUnits(orgUnits, removedSelectedOrgUnitNames, selectedOrgUnits))
            : onChange(selectedOrgUnits);
    }

    return (
        <Box>
            <Autocomplete
                multiple
                id="multi-selector-in-org-units"
                data-testid="multi-selector-in-org-units"
                options={options}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => updateOrgUnits(newValue)}
                value={selectedOrgUnitNames}
                disabled={disabled}
                renderInput={(params) =>
                    <TextField {...params}
                               data-testid="orgunit-input"
                               fullWidth={true} variant="outlined" style={{margin: 5}}
                               label={"Organisationseinheiten:"}/>}
            />
        </Box>
    );
};

export default OrgUnitMultiSelector;
