import {OrgUnit} from "../../model/OrgUnit";
import {User} from "../../model/User";

export let getOrgNames = (orgUnits: OrgUnit[] | undefined) => {
    return (orgUnits || []).filter(a => !!a).map(a => a.orgName);
}
const hasAdminSameOrgUnitAsEditUser = (currentOUnit: OrgUnit, editUser?: User): boolean => {
    if (editUser !== undefined) return !editUser.adminOrgUnits.some(editOrg => currentOUnit.orgName.localeCompare(editOrg.orgName) === 0)
    return true
}

export let isVisibleOrgUnitToUser = (user: User, org: string, editUser?: User): boolean => {
    if (!user.isAdmin && user.adminOrgUnits.length > 0) {
        return user.adminOrgUnits.some(currentOUnit => {
            const isOrgUnitInScope: boolean = currentOUnit.orgName.localeCompare(org) === 0;
            const isEditUserOrgAdmin: boolean = user.email.localeCompare(editUser?.email ?? "") !== 0
                ? hasAdminSameOrgUnitAsEditUser(currentOUnit, editUser) : true
            return isOrgUnitInScope && isEditUserOrgAdmin
        })
    }
    if (user.email.localeCompare(editUser?.email ?? "") === 0)
        return true
    return user.isAdmin
}

export const addRemovedOrgUnits = (orgUnits: OrgUnit[], removedSelectedOrgUnitNames: string[], selectedOrgUnits: OrgUnit[]): OrgUnit[] => {
    const removedSelectedOrgUnits: OrgUnit[] = removedSelectedOrgUnitNames.map(name => orgUnits.find(u => u.orgName === name))
        .filter(e => e !== undefined) as OrgUnit[]
    return [...selectedOrgUnits, ...removedSelectedOrgUnits]
}