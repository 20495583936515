import React, {useCallback, useEffect, useRef, useState} from "react";
import {User} from "../../model/User";
import Button from "@material-ui/core/Button";
import {useUserListStyles} from "../../styles/UsersListStyles";
import {Box, IconButton, TextField} from "@material-ui/core";
import {checkIsAdmin} from "../../functions/Helpers";
import DeleteRoleDialog from "../../components/DeleteRoleDialog";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done'
import WarningDialog from "../../dialogs/WarningDialog";
import SuccessDialog from "../../dialogs/SuccessDialog";
import {DataGrid, deDE, GridColDef, GridRowModes, GridRowModesModel, GridRowsProp} from "@mui/x-data-grid";
import {Role} from "../../model/Role";
import {RoleClient} from "../../api/RoleClient";

interface Props {
    roles: Role[]
    reloadRoles: () => Promise<void>
    reloadUsers: () => Promise<void>
    currentUser: User
    signout: () => void
    displayWarning: (message: string) => void
}

enum RowState {
    NORMAL,
    CREATE,
    EDIT
}

const RolesTable = (props: Props) => {
    const [selectedRole, setSelectedRole] = useState<Role | null>(null)
    const prevSelectedUnit = useRef(selectedRole);

    const [deleteRoleDialogVisible, setDeleteRoleDialogVisible] = useState<boolean>(false)
    const [deleteRoleSuccessDialogVisible, setDeleteRoleSuccessDialogVisible] = useState<boolean>(false)

    const [newRoleName, setNewRoleName] = useState<string>('')
    const [newRoleNameError, setNewRoleNameError] = useState<string | null>(null)

    const [duplicatedWarning, setDuplicatedWarning] = useState<boolean>(false)

    const [rowState, setRowState] = useState<RowState>(RowState.NORMAL)
    const prevRowStateRef = useRef(RowState.NORMAL);

    const classes = useUserListStyles();

    let [rolesState, setRolesState] = useState<Role[]>(props.roles);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const [gridRows, setGridRows] = useState<GridRowsProp>(props.roles.map(role => {
        return {
            id: role.roleId,
            roleId: role.roleId,
            roleName: role.roleName,
            objectRef: role
        }
    }));

    const gridColumns: GridColDef[] = [
        {
            field: 'roleName', headerName: 'Name', headerAlign: 'center', align: 'center', flex: 1, editable: true,
            renderEditCell: (params) => (
                <div>
                    <TextField
                        data-testid={'inputRow'}
                        fullWidth={false}
                        label={"Name"}
                        type="text"
                        value={newRoleName}
                        onChange={(event) => {
                            setNewRoleName(event.target.value)
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={() => {
                            setNewRoleNameError(null)
                        }}
                        placeholder={params.row.objectRef === null ? 'Name' : params.row.objectRef.roleName}
                        name="new-role-name"
                        required
                        variant='standard'
                        error={newRoleNameError !== null}
                        helperText={newRoleNameError}
                        autoFocus
                    />
                    <IconButton style={{float: 'right',}}
                                data-testid={'closeBtn'}
                                onClick={(e) => handleEditCanceled(params.row.objectRef)}>
                        <CloseIcon style={{
                            color: 'red',
                            fontSize: '30pt',
                        }}/></IconButton>
                    <IconButton style={{float: 'right',}}
                                data-testid={'doneBtn'}
                                onClick={(e) => handleEditConfirmed(params.row.objectRef, e)}
                                disabled={shouldAcceptDisabled()}>
                        <DoneIcon style={{
                            color: newRoleName.replace(/\s+/g, '').length <= 0
                            || newRoleName.replace(/\s+/g, '').length > 60 ? 'gray' : 'green',
                            fontSize: '30pt',
                        }}/></IconButton>
                </div>
            ),
            renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>)
        }
    ];

    const handleCreateButtonClicked = () => {
        setRowState(RowState.CREATE);
        const id = "newObj";
        setGridRows((prevRows) => [...prevRows,
            {
                id,
                roleName: "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ",
                objectRef: null
            }
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit},
        }))
        setSelectedRole(null);
    }

    const handleEditButtonClicked = (o?: Role) => {
        if (o) {
            setRowState(RowState.EDIT);
            setRowModesModel({[o.roleId]: {mode: GridRowModes.Edit}});
        }
    }

    const handleEditConfirmed = (o: Role, e: any) => {
        setSelectedRole(o);
        if (o) {
            setRowModesModel({[o.roleId]: {mode: GridRowModes.View, ignoreModifications: true}})
        } else {
            setRowModesModel({"newObj": {mode: GridRowModes.View, ignoreModifications: true}})
            setGridRows(gridRows.filter(elem => elem.objectRef))
        }
        onDoneEditRole(e, o);
        setRowState(RowState.NORMAL);
    }

    const handleEditCanceled = (o: Role) => {
        setSelectedRole(o);
        if (o) {
            setRowModesModel({[o.roleId]: {mode: GridRowModes.View, ignoreModifications: true}})
        } else {
            setRowModesModel({"newObj": {mode: GridRowModes.View, ignoreModifications: true}})
            setGridRows(gridRows.filter(elem => elem.objectRef))
        }
        setRowState(RowState.NORMAL);
    }

    useEffect(() => {
        setRolesState(props.roles)
        setGridRows(props.roles.map(role => {
            return {
                id: role.roleId,
                roleName: role.roleName,
                objectRef: role
            }
        }))
    }, [props.roles])

    useEffect(() => {
        const copyRoles = [...rolesState]
        // add dummy data for the new row
        if (prevRowStateRef.current !== RowState.CREATE && rowState === RowState.CREATE) {
            const newRole = {roleId: "", roleName: ""}
            copyRoles.push({...newRole, roleName: "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ"})
            setRolesState(copyRoles);
            setSelectedRole(newRole)
            setRowState(RowState.CREATE)
        }
        prevRowStateRef.current = rowState;
        prevSelectedUnit.current = selectedRole;

        // if none selected then row is normal
        if (!selectedRole && rowState !== RowState.CREATE) {
            setRowState(RowState.NORMAL)
        }
        setNewRoleName('')
    }, [rowState, selectedRole, rolesState])

    const shouldAcceptDisabled = () => {
        return newRoleName.replace(/\s+/g, '').length <= 0
            || newRoleName.replace(/\s+/g, '').length > 60;
    }

    const onDoneEditRole = (e: any, role: Role) => {
        let copyOfEntries = props.roles
        setRolesState(copyOfEntries)
        e.stopPropagation();
        // check if duplicated
        if (props.roles.every((role) => role.roleName.trim().toLowerCase() !== newRoleName.trim().toLowerCase())) {

            if (role === null) {
                RoleClient.createRole({
                    roleName: newRoleName.trim()
                }).then(() => {
                    props.reloadRoles().then()
                }).finally(() => {
                    props.reloadRoles().then()
                })
            } else {
                RoleClient.editRoleById(role.roleId, {
                    roleName: newRoleName.trim()
                }).then(() => {
                    props.reloadRoles().then()
                }).finally(() => {
                    props.reloadRoles().then()
                }).catch(e => alert(e))
            }
            setSelectedRole(null)
            setRowState(RowState.NORMAL)
        } else {
            setDuplicatedWarning(true)
        }
    }

    const shouldCreateButtonDisabled = () => {
        return rowState !== RowState.NORMAL
    }

    const select = useCallback((role: Role) => {
        if (selectedRole?.roleId === role.roleId)
            setSelectedRole(null)
        else
            setSelectedRole(role)

    }, [selectedRole, setSelectedRole])

    return <div style={{marginBottom: 20, display: "block"}}>
        <WarningDialog text={"Rolle ist bereits im System vorhanden."} isActive={duplicatedWarning}
                       backgroundColor={'#FC4F4F'} horizontalAlignment={"center"} verticalAlignment={"top"}
                       handleWarningDialogClose={() => setDuplicatedWarning(false)}/>
        <SuccessDialog text={"Rolle ist gelöscht."} isActive={deleteRoleSuccessDialogVisible}
                       horizontalAlignment={"center"} verticalAlignment={"top"}
                       handleSuccessDialogClose={() => setDeleteRoleSuccessDialogVisible(false)}/>
        <DeleteRoleDialog visible={deleteRoleDialogVisible}
                          selectedRole={selectedRole}
                          closeDialog={() => setDeleteRoleDialogVisible(false)}
                          reloadRoles={props.reloadRoles}
                          reloadUsers={props.reloadUsers}
                          didDeleteRole={() => {
                              setSelectedRole(null)
                              setDeleteRoleSuccessDialogVisible(true)
                          }}
                          displayWarning={props.displayWarning}
                          signout={props.signout}/>
        <div className={"topHeading"} style={{textAlign: "center"}}>
            <h1 style={{marginTop: 8, marginBottom: 8}}> Rollen</h1>
        </div>
        <div className={classes.list} style={{textAlign: 'center', margin: "0 40px"}}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{field: 'roleName', sort: 'asc'}],
                    },
                }}
                editMode="row"
                isCellEditable={(params) => params.row.objectRef === selectedRole}
                rowModesModel={rowModesModel}
                rows={gridRows}
                columns={gridColumns}
                onRowClick={(params) => select(params.row.objectRef)}
                rowHeight={41}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#ededed',
                    }
                }}
                rowsPerPageOptions={[]}
                autoHeight={true}
                pagination={undefined}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                experimentalFeatures={{newEditingApi: true}}
            />
        </div>
        <Box style={{textAlign: 'center', margin: 8}}>
            <Button
                disabled={!checkIsAdmin(props.currentUser) || shouldCreateButtonDisabled()}
                color={"primary"}
                variant={"contained"}
                onClick={() => handleCreateButtonClicked()}
                className={classes.button}
                data-testid={'createBtn'}
            >
                Anlegen
            </Button>
            <Button
                disabled={selectedRole === null || rowState === RowState.CREATE}
                color={"primary"}
                variant={"contained"}

                onClick={() => handleEditButtonClicked(selectedRole!)}
                className={classes.button}
                data-testid={'editBtn'}
            >
                Editieren
            </Button>
            <Button
                disabled={selectedRole === null || rowState === RowState.CREATE}
                color={"primary"}
                variant={"contained"}
                onClick={() => setDeleteRoleDialogVisible(true)}
                className={classes.button}
                data-testid={'deleteBtn'}
            >
                Löschen
            </Button>
        </Box>
    </div>
}


export default RolesTable
