import {Auth} from "aws-amplify";
import {User} from "../model/User";
import {UpdateUserRequest} from "../model/request/UpdateUserRequest";
import {ChangePasswordRequest} from "../model/request/ChangePasswordRequest";
import {UserRequest} from "../model/request/UserRequest";
import {ConfirmStatus} from "../views/Pages/ConfirmPage";
import {DenyStatus} from "../views/Pages/DenyPage";
import {OrgUnit} from "../model/OrgUnit";

export class UserClient {

    private static readonly baseURL = process.env.REACT_APP_BASE_URL;

    static getAccessToken(): Promise<string | undefined> {
        return Auth.currentSession().then(cognitoUserSession => cognitoUserSession.getAccessToken().getJwtToken());
    }

    static async getSelf(): Promise<User> {
        const url = UserClient.baseURL + "rest/users/self";
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }

    static async getConfirmedUsers(): Promise<User[]> {
        const url = UserClient.baseURL + "rest/users";
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }

    static async getConfirmedUsersWithPagination(_limit: number, paginationToken: string): Promise<any> {
        let url = UserClient.baseURL + "rest/users/pagination?limit=" + _limit;
        if (paginationToken && paginationToken !== "")
            url += "&token=" + paginationToken;
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }

    static async getConfirmedUsersWithFilter(filter: any): Promise<any> {
        const requestOption = filter.filterModel.items[0];
        let url = UserClient.baseURL + "rest/users/filter?column=" + requestOption.columnField + "&value=" + requestOption.value;
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }

    static async getUnconfirmedUsers(): Promise<User[]> {
        const url = UserClient.baseURL + "rest/users/unconfirmed";
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            try {
                return res.json()
            } catch (e) {
                return []
            }
        })
    }

    static async isConfirmed(username: string): Promise<boolean> {
        const token = await this.getAccessToken() || "";
        const url = UserClient.baseURL + "rest/users/is-confirmed/" + username;
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => res.json())
    }

    static async updateSelfData(updateUserRequest: UpdateUserRequest): Promise<string> {
        const url = UserClient.baseURL + "rest/users/updateSelfData";
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateUserRequest)
        }).then(res => res.text())
    }

    static async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<string> {
        const url = UserClient.baseURL + "rest/users/changePassword";
        const token = await this.getAccessToken() || "";
        changePasswordRequest.accessToken = token;
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(changePasswordRequest)
        }).then(res => res.text())
    }

    static async enableUser(user: User): Promise<string> {
        const url = UserClient.baseURL + "rest/users/enableUser/" + user.username;
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
        }).then(res => res.text())
    }

    static async deleteUser(user: User): Promise<string> {
        const url = UserClient.baseURL + "rest/users/deleteUser/" + user.username;
        const token = await this.getAccessToken() || "";
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
        }).then(res => res.text())
    }

    static async enableUserWithToken(username: string, token: string, enabledApplications: string[], orgUnit: OrgUnit,
                                     selectedRoles: string[]): Promise<ConfirmStatus> {
        const url = UserClient.baseURL + "rest/users/confirm";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                username: username,
                token: token,
                enabledApplications: enabledApplications,
                orgUnit: orgUnit,
                admin2getherRoles: selectedRoles
            }),
        })
        switch (response.status) {
            case 200:
                return ConfirmStatus.CONFIRMED;
            case 409:
                return ConfirmStatus.ALREADY_CONFIRMED;
            case 410:
                return ConfirmStatus.NOT_FOUND;
            case 401:
                return ConfirmStatus.INVALID_TOKEN;
            default:
                return ConfirmStatus.UNKNOWN;
        }
    }

    static async deleteUserWithToken(username: string, token: string): Promise<DenyStatus> {
        const url = UserClient.baseURL + "rest/users/deny";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                username: username,
                token: token,
            }),
        })
        switch (response.status) {
            case 200:
                return DenyStatus.DENIED
            case 409:
                return DenyStatus.ALREADY_CONFIRMED;
            case 410:
                return DenyStatus.NOT_FOUND;
            case 401:
                return DenyStatus.INVALID_TOKEN
            default:
                return DenyStatus.UNKNOWN
        }
    }

    static async createUser(request: UserRequest): Promise<void> {
        const url = UserClient.baseURL + "rest/users";
        const token = await this.getAccessToken() || "";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(request)
        })
        switch (response.status) {
            case 201:
                return
            case 409:
                return Promise.reject("E-Mail-Adresse wird bereits verwendet")
            default:
                let errorMessage = await response.json() as Error
                return Promise.reject(errorMessage.message)
        }
    }

    static async updateUser(request: UpdateUserRequest): Promise<User> {
        const url = UserClient.baseURL + "rest/users/" + request.username;
        const token = await this.getAccessToken() || "";
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(request)
        })
        switch (response.status) {
            case 200:
                return await response.json()
            default:
                let errorMessage = await response.json() as Error
                return Promise.reject(errorMessage.message)
        }
    }

    static async moveUserToDeletedPool(userId: string): Promise<string> {
        const url = UserClient.baseURL + `rest/users/moveUserToDeletedPool/${userId}`;
        const token = await this.getAccessToken() || "";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            }
        })
        switch (response.status) {
            case 200:
                return response.text()
            case 410:
                return Promise.reject("Nutzer ist nicht im System vorhanden")
            case 500:
                return Promise.reject("Internal Error. Please contact support.")
            default:
                let errorMessage = await response.json() as Error
                return Promise.reject(errorMessage.message || "Internal Server Error")
        }
    }
}