import React, {useCallback, useState} from 'react'
import {MenuNav} from "@2gether/frontend-library";

import Admin2GetherLogo from "../../logos/Admin2Gether-Logo.png"
import MyDataComponent from "../MyData/MyDataComponent";
import SuccessSnackbar from "../../dialogs/SuccessSnackbar";
import {User} from "../../model/User";
import ChangePasswordComponent from "../ChangePassword/ChangePasswordComponent";
import NonAdminUserPage from "../Users/NonAdminUserPage";
import UserPage from "../Users/UserPage";
import WarningSnackbar from "../../dialogs/WarningSnackbar";
import {checkIsAdminOrOrgUnitAdmin} from "../../functions/Helpers";

interface Props {
    signOut: () => void
    currentUser: User
    updateUserCredentials: () => Promise<void>
}

export const HomePageView = (props: Props) => {
    const {signOut, currentUser, updateUserCredentials} = props;

    const [showMyDataDialog, setShowMyDataDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [showWarningSnackbar, setShowWarningSnackbar] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

    const handleSuccess = useCallback((successMessage: string) => {
        setShowSuccessDialog(true);
        setSuccessMessage(successMessage);
    }, [])

    const handleWarning = useCallback((warningMessage: string) => {
        setShowWarningSnackbar(true);
        setWarningMessage(warningMessage);
    }, [])

    const renderMenuNav = () => {
        return <MenuNav
            username={currentUser.givenName + " " + currentUser.familyName}

            imageSource={Admin2GetherLogo}

            items={[]}

            userItems={[
                {
                    name: "Meine Daten",
                    show: true,
                    onClick: () => {
                        setShowMyDataDialog(true);
                    }
                },
                {
                    name: "Passwort ändern",
                    show: true,
                    onClick: () => {
                        setShowChangePasswordDialog(true)
                    }
                }]}
            nestedItems={[]}


            onLogoClick={() => {
            }}
            signout={signOut}
        />
    }

    const renderUserPage = () => {
        if (checkIsAdminOrOrgUnitAdmin(currentUser)) {
            return <UserPage
                updateUserCredentials={updateUserCredentials}
                currentUser={currentUser}
                displayWarning={handleWarning}
                signout={signOut}
            />
        }
        return <NonAdminUserPage
            updateUserCredentials={updateUserCredentials}
            currentUser={currentUser}
            displayWarning={handleWarning}
            signout={signOut}
        />
    }

    return (
        <div>
            <SuccessSnackbar
                isActive={showSuccessDialog}
                handleSuccessDialogClose={() => setShowSuccessDialog(false)}
                verticalAlignment={'bottom'}
                horizontalAlignment={'left'}
                text={successMessage}
            />
            <WarningSnackbar text={warningMessage}
                             isActive={showWarningSnackbar}
                             backgroundColor={'red'}
                             horizontalAlignment={'left'}
                             verticalAlignment={'bottom'}
                             handleWarningDialogClose={() => setShowWarningSnackbar(false)}/>
            <MyDataComponent updateUserCredentials={updateUserCredentials}
                             callbackToUpdateActiveUsers={() => {
                             }}
                             showMyDialog={showMyDataDialog}
                             onClose={() => setShowMyDataDialog(false)}
                             handleSuccess={handleSuccess}
                             callbackToUpdateCome2Gether={() => {
                             }}
                             userData={currentUser}/>
            <ChangePasswordComponent
                showChangePasswordDialog={showChangePasswordDialog}
                onClose={() => setShowChangePasswordDialog(false)}
            />
            {renderMenuNav()}
            {renderUserPage()}
        </div>
    )
}
