import {CircularProgress, DialogActions, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, {useCallback, useState} from "react";
import {RoleClient} from "../api/RoleClient";
import {Role} from "../model/Role";

interface Props {
    visible: boolean
    selectedRole: Role | null
    closeDialog: () => void
    reloadRoles: () => void
    reloadUsers: () => void
    didDeleteRole: () => void
    displayWarning: (message: string) => void
    signout: () => void
}

const DeleteRolesDialog = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onConfirm = useCallback(() => {
        setIsLoading(true)
        const selectedRole = props.selectedRole
        if (selectedRole !== null)
            RoleClient.deleteRoleById(selectedRole.roleId).then(() => {
                props.reloadRoles();
                props.reloadUsers();
                props.didDeleteRole()
            }).catch(e => {
                props.displayWarning(e.toString())
            }).finally(() => {
                setIsLoading(false)
                props.closeDialog()
            })
        else
            props.closeDialog()
    }, [props, setIsLoading])

    return (
        <Dialog open={props.visible} fullWidth={true} onEnter={() => setIsLoading(false)}>
            <DialogContent>
                <div>Möchten Sie wirklich "{props.selectedRole?.roleName ?? "-"}" löschen?</div>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={onConfirm} disabled={isLoading}>
                    Bestätigen {
                    isLoading &&
                    <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}/>
                }
                </Button>
                <Button color={"secondary"} variant={"contained"} onClick={props.closeDialog} disabled={isLoading}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default DeleteRolesDialog;
