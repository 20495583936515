import {User} from "../../model/User";
import {checkIsAdmin, checkIsAdminOrOrgUnitAdmin, isOnlyRead} from "../../functions/Helpers";
import {Endpoint} from "../../api/EndpointClient";
import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputAdornment,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {isValidEmailAddress, isValidPassword} from "@2gether/frontend-library";
import ACLMultiSelector from "../../components/ACLMultiSelector";
import {AclClient} from "../../api/AclClient";
import {OrgUnit} from "../../model/OrgUnit";
import OrgUnitMultiSelector from "../../components/OrgUnitMultiSelector";
import AdminRightsMultiSelector from "../../components/AdminRightsMultiSelector";
import {UserRequest} from "../../model/request/UserRequest";
import {UserClient} from "../../api/UserClient";
import {Role} from "../../model/Role";
import Admin2GetherRolesMultiSelector from "../../components/Admin2GetherRolesMultiSelector";

export enum DialogState {
    CLOSED,
    CREATE,
    EDIT
}

interface Props {
    dialogState: DialogState
    close: () => void
    userChanged: (changedUser: User) => void
    userAdded: () => void
    editUser: User | null
    allUserEmailAdresses: string[]
    connectedEnpoints: Endpoint[]
    connectedOrgUnits: OrgUnit[]
    allAdminOrgUnits: OrgUnit[]
    admin2getherRoles: Role[]
    getEndpointRole: (endpoint: Endpoint, user: User) => string
    reloadData: () => Promise<void>
    currentUser: User
}

const UserDialog = (props: Props) => {
    const currentUser = props.currentUser;

    const isCurrentUserEditingThemselves = currentUser.email === (props.editUser?.email ?? "");
    const isCurrentUserAllowedToEditTextField = isCurrentUserEditingThemselves || checkIsAdmin(currentUser);
    const hasOnlyReadPermissions = isOnlyRead(currentUser, props.editUser);

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [givenName, setGivenName] = useState<string | null>(props.editUser?.givenName ?? null)
    const [givenNameError, setGivenNameError] = useState<string | null>(null)

    const [name, setName] = useState<string | null>(props.editUser?.familyName ?? null)
    const [nameError, setNameError] = useState<string | null>(null)

    const [email, setEmail] = useState<string | null>(props.editUser?.email ?? null)
    const [emailError, setEmailError] = useState<string | null>(null)

    const [mattermostName, setMattermostName] = useState<string | null>(props.editUser?.mattermostUsername ?? null)

    const [password, setPassword] = useState<string | null>(null)
    const [passwordError, setPasswordError] = useState<string | null>(null)

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(props.editUser?.isAdmin ?? false)
    const [isMaternaEmployee, setIsMaternaEmployee] = useState<boolean>(props.editUser?.isMaternaEmployee ?? false)

    const [applicationACL, setApplicationACL] = useState<string[]>([])
    const [allAclOptions, setAllAclOptions] = useState<string[]>([])

    const [selectedOrgUnits, setSelectedOrgUnits] = useState<OrgUnit[]>([])
    const [selectedAdminOrgUnits, setSelectedAdminOrgUnits] = useState<OrgUnit[]>([])

    const [selectedAdmin2getherRoles, setSelectedAdmin2getherRoles] = useState<string[]>([])

    useEffect(() => {
        AclClient.getApplications().then(apps => setAllAclOptions(apps))
    }, [])

    const setPreselectedACLOptions = useCallback(() => {
        if (props.dialogState === DialogState.CREATE) {
            setApplicationACL(allAclOptions)
        } else if (props.dialogState === DialogState.EDIT) {
            getPreselectedACL(props.editUser!).then(setApplicationACL)
        }
    }, [props.dialogState, props.editUser, allAclOptions, setApplicationACL])

    let initialRoles: string[]
    const user = props.editUser
    if (user !== null && props.dialogState === DialogState.EDIT)
        initialRoles = props.connectedEnpoints.map((endpoint) => props.getEndpointRole(endpoint, user))
    else
        initialRoles = props.connectedEnpoints.map((endpoint) => endpoint.defaultRole)

    const [roles, setRoles] = useState<string[]>(initialRoles)

    const dialogTitle = props.editUser === null ? "Nutzer anlegen" : "Nutzer editieren";
    const emailPattern = /.+@materna.(de|group|com)$/;


    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const setupState = useCallback(() => {
        setGivenName(props.editUser?.givenName ?? null)
        setGivenNameError(null)
        setName(props.editUser?.familyName ?? null)
        setNameError(null)
        setEmail(props.editUser?.email ?? null)
        setEmailError(null)
        setMattermostName(props.editUser?.mattermostUsername ?? null)
        setPassword(null)
        setPasswordError(null)
        setShowPassword(false)
        setIsAdmin(props.editUser?.isAdmin ?? false)
        setIsMaternaEmployee(props.editUser?.isMaternaEmployee ?? false)
        setPreselectedACLOptions()
        if (user !== null && props.dialogState === DialogState.EDIT)
            setRoles(props.connectedEnpoints.map((endpoint) => props.getEndpointRole(endpoint, user)))
        else
            setRoles(props.connectedEnpoints.map((endpoint) => endpoint.defaultRole))

        if (user !== null && props.dialogState === DialogState.EDIT) {
            setSelectedOrgUnits(user.orgUnits)
            setSelectedAdminOrgUnits(user.adminOrgUnits)
            setSelectedAdmin2getherRoles(user.admin2getherRoles)
        } else {
            setSelectedOrgUnits([]);
            setSelectedAdminOrgUnits([]);
            setSelectedAdmin2getherRoles([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props,
        setApplicationACL,
        setGivenName, setGivenNameError,
        setName, setNameError,
        setEmail, setEmailError,
        setMattermostName,
        setPassword, setPasswordError,
        setRoles,
        setShowPassword,
        setIsAdmin,
        setIsMaternaEmployee,
        setSelectedOrgUnits,
        setSelectedAdminOrgUnits
    ])

    const canSaveWithGivenInput = useCallback(() => {
        let errorInAnyField = false
        const isEmptyString = " darf nicht leer sein"
        if ((givenName?.trim().length ?? 0) === 0) {
            setGivenNameError("Vorname" + isEmptyString)
            errorInAnyField = true
        }
        if ((name?.trim().length ?? 0) === 0) {
            setNameError("Name" + isEmptyString)
            errorInAnyField = true
        }
        if ((email?.trim().length ?? 0) === 0) {
            setEmailError("E-Mail-Adresse" + isEmptyString)
            errorInAnyField = true
        } else {
            const emailAddress = email?.trim() ?? ""
            if (!isValidEmailAddress(emailAddress)) {
                setEmailError("Bitte trage eine gültige E-Mail-Adresse ein")
                errorInAnyField = true
            } else {
                if (props.dialogState === DialogState.CREATE ||
                    (props.dialogState === DialogState.EDIT && emailAddress !== props.editUser?.email)) {
                    const lowerCaseEmailAddress = emailAddress.toLowerCase();
                    if (props.allUserEmailAdresses.find(email => email?.toLowerCase() === lowerCaseEmailAddress) !== undefined) {
                        setEmailError("E-Mail-Adresse bereits vergeben")
                        errorInAnyField = true
                    }
                }
            }
        }

        if (props.dialogState === DialogState.CREATE && (password?.trim().length ?? 0) === 0) {
            setPasswordError("Passwort" + isEmptyString)
            errorInAnyField = true
        }
        if (password !== null && password.trim().length > 0) {
            if (!isValidPassword(password.trim())) {
                setPasswordError("Passwort entspricht nicht den Sicherheitskriterien")
                errorInAnyField = true
            }
        }
        return !errorInAnyField
    }, [
        props,
        givenName, setGivenNameError,
        name, setNameError,
        email, setEmailError,
        password, setPasswordError,
    ])

    const textFieldStyle: CSSProperties = {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        marginBottom: 19,
    }
    const errorTextFieldStyle: CSSProperties = {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        marginBottom: -4,
    }

    const handleUnknownError = useCallback((e) => {
        props.reloadData().then(() => {
            if (canSaveWithGivenInput()) {
                setErrorMessage(e.toString());
            }
        })
    }, [
        props,
        setErrorMessage,
        canSaveWithGivenInput
    ])

    const displayedOrgUnits: OrgUnit[] = [...props.connectedOrgUnits].sort((a: OrgUnit, b: OrgUnit) => a.orgName.localeCompare(b.orgName));
    const displayedAdmin2getherRoles: Role[] = [...props.admin2getherRoles].filter(role => role && role.roleName).sort((a: Role, b: Role) => a.roleName.localeCompare(b.roleName));


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        if (checkIsAdminOrOrgUnitAdmin(currentUser)) setIsMaternaEmployee(emailPattern.test(newEmail));
        setEmail(event.target.value);
    };
    const handleMaternaStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMaternaEmployee(event.target.checked)
    };
    const handleAdminStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tmp = applicationACL;
        if (event.target.checked) {
            setIsAdmin(true);
            tmp.push("Admin");
            setApplicationACL(tmp);
        } else {
            setIsAdmin(false);
            let index = tmp.indexOf("Admin");
            if (index !== -1) {
                tmp.splice(index, 1);
                setApplicationACL(tmp);
            }
        }
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleUserSaveButton = () => {
        setIsLoading(true)
        const canSave = canSaveWithGivenInput()
        if (canSave) {
            const request: UserRequest = {
                username: props.editUser?.username ?? "",
                email: email?.trim() ?? "",
                givenName: givenName?.trim() ?? "",
                familyName: name?.trim() ?? "",
                mattermostUsername: mattermostName?.trim() ?? "",
                password: password?.trim() ?? "",
                isAdmin: isAdmin,
                isMaternaEmployee: isMaternaEmployee,
                applicationACL: applicationACL,
                roles: roles,
                admin2getherRoles: selectedAdmin2getherRoles,
                orgUnits: selectedOrgUnits,
                adminOrgUnits: selectedAdminOrgUnits
            }
            if (props.dialogState === DialogState.CREATE) {
                UserClient.createUser(request).then(() => {
                    props.userAdded()
                    props.close()
                    setIsLoading(false)
                }).catch((e) => {
                    if (e.toString().startsWith("E-Mail")) {
                        props.reloadData().then(() => {
                            if (canSaveWithGivenInput())
                                setEmailError(e.toString())
                            setIsLoading(false)
                        })
                    } else {
                        handleUnknownError(e)
                        setIsLoading(false)
                    }
                })
            } else if (props.dialogState === DialogState.EDIT) {
                UserClient.updateUser(request).then((user) => {
                    props.userChanged({
                        username: request.username,
                        email: request.email,
                        givenName: request.givenName,
                        familyName: request.familyName,
                        mattermostUsername: request.mattermostUsername,
                        isAdmin: request.isAdmin,
                        isMaternaEmployee: request.isMaternaEmployee,
                        applicationACL: request.applicationACL,
                        roles: request.roles,
                        admin2getherRoles: request.admin2getherRoles,
                        orgUnits: user.orgUnits,
                        adminOrgUnits: user.adminOrgUnits
                    })
                    props.close()
                    setIsLoading(false)
                }).catch((e) => {
                    handleUnknownError(e)
                    setIsLoading(false)
                })
            } else {
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
        }
    }
    const createRoleTextField = (endpoint: Endpoint, index: number) => {
        const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newRoles = [...roles];
            newRoles[index] = event.target.value;
            setRoles(newRoles);
        }

        const renderRole = (role: string) => (<option key={role} value={role}>{role} </option>)
        const renderRoles = () => endpoint.roles.map((role) => renderRole(role))

        return (<TextField
            data-testid="text-field-roles"
            key={index}
            fullWidth={true}
            label={endpoint.name}
            select
            SelectProps={{
                native: true,
            }}
            value={roles[index]}
            onChange={handleRoleChange}
            name={endpoint.name}
            required
            variant="outlined"
            style={textFieldStyle}
            disabled={!checkIsAdmin(currentUser)}
        >
            {renderRoles()}
        </TextField>)
    }

    return (
        <Dialog
            open={props.dialogState !== DialogState.CLOSED}
            onEnter={setupState}
            onExited={setupState}
            fullWidth={true}
            scroll={'paper'}
            maxWidth={'sm'}
            id={'user-dialog'}
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <div id={"errorMessageDialog"}>
                    <Dialog open={errorMessage !== null}>
                        <DialogTitle>Fehler</DialogTitle>
                        <DialogContent>
                            <div style={{whiteSpace: "pre-line"}}>
                                {errorMessage ?? ""}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => setErrorMessage(null)}
                            >
                                Schließen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <form>
                    <TextField
                        fullWidth={true}
                        label={"Vorname"}
                        type="text"
                        value={givenName}
                        onChange={(event) => setGivenName(event.target.value)}
                        onFocus={() => setGivenNameError(null)}
                        placeholder="Vorname"
                        name="given-name"
                        required
                        variant="outlined"
                        error={givenNameError !== null}
                        helperText={givenNameError}
                        style={givenNameError !== null ? errorTextFieldStyle : textFieldStyle}
                        disabled={hasOnlyReadPermissions}
                        inputProps={{maxLength: 64}}
                    />
                    <TextField
                        fullWidth={true}
                        label={"Name"}
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onFocus={() => setNameError(null)}
                        placeholder="Name"
                        name="name"
                        required
                        variant="outlined"
                        error={nameError !== null}
                        helperText={nameError}
                        style={nameError !== null ? errorTextFieldStyle : textFieldStyle}
                        disabled={hasOnlyReadPermissions}
                        inputProps={{maxLength: 64}}
                    />
                    <TextField
                        fullWidth={true}
                        label={"E-Mail-Adresse"}
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        onFocus={() => setEmailError(null)}
                        placeholder="E-Mail-Adresse"
                        name="email"
                        required
                        variant="outlined"
                        error={emailError !== null}
                        helperText={emailError}
                        style={emailError !== null ? errorTextFieldStyle : textFieldStyle}
                        disabled={hasOnlyReadPermissions}
                    />

                    <FormControl fullWidth={true} variant={"outlined"}>
                        <Admin2GetherRolesMultiSelector userRoles={selectedAdmin2getherRoles}
                                                        onChange={(values: string[]) => setSelectedAdmin2getherRoles(values)}
                                                        disabled={!checkIsAdmin(currentUser)}
                                                        allRoles={displayedAdmin2getherRoles}
                                                        data-testid="roles-multi-selector"/>
                        <OrgUnitMultiSelector selected={selectedOrgUnits}
                                              onChange={(values: OrgUnit[]) => setSelectedOrgUnits(values)}
                                              disabled={!checkIsAdminOrOrgUnitAdmin(currentUser)}
                                              orgUnits={displayedOrgUnits}
                                              user={currentUser}
                                              editUser={props.editUser ?? undefined}
                        />
                        <AdminRightsMultiSelector selected={selectedAdminOrgUnits}
                                                  onChange={(values: OrgUnit[]) => setSelectedAdminOrgUnits(values)}
                                                  disabled={!checkIsAdmin(currentUser)}
                                                  orgUnits={displayedOrgUnits}
                                                  data-testid="admin-rights-multi-selector"
                        />
                    </FormControl>

                    <TextField
                        data-testid="text-field-password"
                        fullWidth={true}
                        label={"Passwort"}
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        disabled={!isCurrentUserAllowedToEditTextField}
                        onFocus={() => setPasswordError(null)}
                        placeholder="Passwort"
                        name="password"
                        required={props.dialogState === DialogState.CREATE}
                        variant="outlined"
                        error={passwordError !== null}
                        helperText={passwordError}
                        style={passwordError !== null ? errorTextFieldStyle : textFieldStyle}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(() => setShowPassword(!showPassword))}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <FormControl fullWidth={true} variant={"outlined"}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-testid="text-field-is-admin"
                                    name="isAdmin"
                                    checked={isAdmin}
                                    disabled={!checkIsAdmin(currentUser)}
                                    onChange={handleAdminStatusChange}
                                />}
                            label="Admin"
                            style={{margin: 5}}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-testid="text-field-is-materna-employee"
                                    name="isMaternaEmployee"
                                    disabled={!checkIsAdminOrOrgUnitAdmin(currentUser)}
                                    checked={isMaternaEmployee}
                                    onChange={handleMaternaStatusChange}
                                />}
                            label="Materna-Mitarbeiter"
                            style={{margin: 5}}
                        />
                    </FormControl>
                    {props.connectedEnpoints.length > 0 && props.connectedEnpoints.map((endpoint, index) => createRoleTextField(endpoint, index))}
                    <FormControl fullWidth={true} variant={"outlined"}>
                        <ACLMultiSelector selected={applicationACL}
                                          onChange={(values: string[]) => setApplicationACL(values)}
                                          disabled={!checkIsAdmin(currentUser)}/>
                    </FormControl>
                </form>
            </DialogContent>


            <DialogActions>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    disabled={isLoading}
                    onClick={handleUserSaveButton}
                    id={"save-user-dialog-button"}
                >
                    Speichern
                    {isLoading &&
                        <CircularProgress color="inherit" style={{marginLeft: 5}} size={20}
                        />}
                </Button>
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => props.close()}
                    id={"close-user-dialog-button"}
                >
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export async function getPreselectedACL(editUser: User) {
    let apps = await AclClient.getApplicationsForUser(editUser)
    return editUser?.isAdmin ? [...apps, 'Admin'] : apps
}

export default UserDialog